import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['popup', 'button', 'field'];

  declare popupTarget: HTMLElement;
  declare buttonTarget: HTMLElement;
  declare fieldTarget: HTMLInputElement;

  private originalValue: string;

  connect(): void {
    this.originalValue = this.fieldTarget.value;
    this.hidePopup();
  }

  toggle(): void {
    if (this.buttonTarget.classList.contains('active')) {
      this.cancel();
    } else {
      this.showPopup();
    }
  }

  open(): void {
    this.showPopup();
  }

  /**
   * Turbo が発火するように submit イベントを送信してサブミットさせる
   *
   * @param event
   */
  submit(event: MouseEvent): void {
    const button = event.currentTarget as HTMLButtonElement;
    const form = button.closest('form');
    form.dispatchEvent(new CustomEvent('submit', { bubbles: true }));
  }

  apply(): void {
    const managementTitle = this.buttonTarget.querySelector('span');
    managementTitle.textContent = this.fieldTarget.value;
    this.originalValue = this.fieldTarget.value;
    this.hidePopup();
  }

  /**
   * ブックボード管理名編集時、IMEで変換中にenterキーを押下したかどうかを考慮する
   * apply（ブックボード編集画面）の場合に適用する
   * submit（ブックボード一覧）の場合は、ブラウザのデフォルトの挙動に任せるので何もしない
   */
  imeComposing(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !event.isComposing) {
      if (this.fieldTarget.dataset.submitAction == 'apply') this.apply();
    }
  }

  cancel(): void {
    this.fieldTarget.value = this.originalValue;

    // fieldTarget.valueの変更にあたり、バリデーションエラー更新のためinputイベントを呼び出す
    this.fieldTarget.dispatchEvent(new Event('input'));

    this.hidePopup();
  }

  private showPopup(): void {
    this.buttonTarget.classList.add('active');
    this.popupTarget.style.display = 'block';
  }

  private hidePopup(): void {
    this.buttonTarget.classList.remove('active');
    this.popupTarget.style.display = 'none';
  }
}
