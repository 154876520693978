import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['link'];
  declare linkTarget: HTMLAnchorElement;

  openLinkInNewTab(event: MouseEvent) {
    event.preventDefault();
    if (confirm('ページを離れますか？行った変更が保存されない可能性があります。')) {
      window.open(`${this.linkTarget.href}`, '_blank');
    }
  }
}
