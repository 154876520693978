import { Controller } from '@hotwired/stimulus';

/**
 * ファイルアップローダー操作用コントローラー
 * ファイルアップロード機能自体はないです。sync-data controller に移譲をしています
 *
 * 機能
 * - ネイティブ以外でのファイラー表示機能 (#openFiler)
 * - ファイル選択後のプレビュー表示 (#preview)
 * - アップロード済みのファイル削除 (#remove)
 *
 * 使い方
 * <div data-controller="upload"
 *      data-upload-endpoint-value="<Upload URL>"
 *      data-upload-csrf-token-value="<%= form_authenticity_token %>"
 * >
 *   <button type="button" data-action="click->upload#openFiler">
 *   <img src="" data-upload-target="preview">
 *   <%= form.file_field :logo,
 *                       accept: '.jpg,.gif,.png,image/gif,image/jpeg,image/png',
 *                       data: {
 *                         upload_target: 'input',
 *                         action: 'change->upload#preview'
 *                       } %>
 *   <button type="button"
 *           data-upload-target="remove"
 *           data-action="click->upload#remove">画像を削除</button>
 * </div>
 */
export default class extends Controller {
  static values = {
    endpoint: String,
    csrfToken: String,
  };
  static targets = ['input', 'label', 'preview', 'remove'];

  declare endpointValue: string;
  declare csrfTokenValue: string;
  declare labelTarget: HTMLElement;
  declare inputTarget: HTMLInputElement;
  declare previewTarget: HTMLImageElement;
  declare removeTarget: HTMLButtonElement;

  connect(): void {
    if (this.previewTarget.getAttribute('src')) {
      this.hideLabel();
    } else {
      this.hideRemoveButton();
    }
  }

  openFiler(): void {
    this.inputTarget.click();
  }

  preview(): void {
    const file = this.inputTarget.files[0];
    const blobUrl = window.URL.createObjectURL(file);
    this.previewTarget.src = blobUrl;
    this.hideLabel();
    this.showRemoveButton();
  }

  async remove(): Promise<void> {
    const formData = new FormData();
    formData.set('_method', 'delete');
    formData.set('authenticity_token', this.csrfTokenValue);

    const result = await fetch(this.endpointValue, {
      method: 'POST',
      mode: 'no-cors',
      body: formData,
    });

    if (result.status === 200) {
      this.inputTarget.value = null;
      this.previewTarget.src = '';
      this.showLabel();
      this.hideRemoveButton();
    } else {
      alert('ファイルの削除に失敗しました');
    }
  }

  private hideLabel(): void {
    this.labelTarget.style.display = 'none';
  }

  private showLabel(): void {
    this.labelTarget.style.display = 'block';
  }

  private hideRemoveButton(): void {
    this.removeTarget.classList.add('hide');
  }

  private showRemoveButton(): void {
    this.removeTarget.classList.remove('hide');
  }
}
