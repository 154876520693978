import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'checkboxes'];
  static values = {
    defaultText: String,
    className: String,
    isRadioButton: Boolean,
  };
  declare contentTarget: HTMLElement;
  declare checkboxesTargets: HTMLInputElement[];
  declare defaultTextValue?: string;
  declare classNameValue: string;
  declare isRadioButtonValue: boolean;

  connect(): void {
    this.changeSelectedLabel();
  }

  changeSelectedLabel(): void {
    this.contentTarget.textContent = this.selectedValues();
    this.contentTarget.insertAdjacentHTML('beforeend', '<span class="arrow_down"></span>');
  }

  private selectedValues(): string {
    const optionCheckboxes = document.querySelectorAll(`.${this.classNameValue}:checked`);
    if (this.isRadioButtonValue) {
      return this.selectedOptionLabels(optionCheckboxes);
    }

    // FIXME: 直す
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return this.checkedAllLabel(optionCheckboxes) || [...optionCheckboxes].map((v) => v.dataset.label).join(', ');
  }

  /**
   * 選ばれている選択肢の状態を反映した文字列を返す e.g. '指定なし', '公開中・通常のブックボード'
   */
  private selectedOptionLabels(selectedElements: NodeListOf<Element>): string {
    const selectedOptions = Array.from(selectedElements) as HTMLOptionElement[];
    const isUnspecified: boolean = selectedOptions.every((option) => option.value === 'unspecified');
    const unspecifiedText = '指定なし';
    if (isUnspecified) {
      return this.defaultTextValue ? `${this.defaultTextValue}: ${unspecifiedText}` : unspecifiedText;
    }

    return selectedOptions
      .map((option) => option.dataset.label)
      .filter((label) => label !== unspecifiedText)
      .join('・');
  }

  private checkedAllLabel(optionCheckboxes): string {
    const length = optionCheckboxes.length;
    if (!length || optionCheckboxes[0]?.value == 'unspecified') {
      return `${this.defaultTextValue}: 指定なし`;
    } else if (length == this.checkboxesTargets.length) {
      return `${this.defaultTextValue}: すべて`;
    }
  }
}
