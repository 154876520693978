import Litepicker from 'litepicker';
document.addEventListener('turbo:load', () => {
  const pickerBeginElement = document.querySelector<HTMLInputElement>('#js-datepicker-single-begin');
  const pickerEndElement = document.querySelector<HTMLInputElement>('#js-datepicker-single-end');
  // ホームに戻った時にconsoleにエラー表示が出るため
  if (!pickerBeginElement || !pickerEndElement) return;

  const inputBeginDateClass = pickerBeginElement.dataset.targetInputDateBegin;
  const inputEndDateClass = pickerEndElement.dataset.targetInputDateEnd;
  const inputBeginDate = document.querySelector(`.${inputBeginDateClass}`) as HTMLInputElement;
  const inputEndDate = document.querySelector(`.${inputEndDateClass}`) as HTMLInputElement;

  new Litepicker({
    element: pickerBeginElement,
    lang: 'ja-JP',
    singleMode: true,
    format: 'YYYY/MM/DD',
    setup: (beginPicker) => {
      beginPicker.on('selected', (date) => {
        inputBeginDate.value = date.dateInstance.toLocaleDateString('ja', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
        inputBeginDate.dispatchEvent(new CustomEvent('change', { bubbles: true }));
      });
    },
  });

  new Litepicker({
    element: pickerEndElement,
    lang: 'ja-JP',
    singleMode: true,
    format: 'YYYY/MM/DD',
    setup: (endPicker) => {
      endPicker.on('selected', (date) => {
        inputEndDate.value = date.dateInstance.toLocaleDateString('ja', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
        inputEndDate.dispatchEvent(new CustomEvent('change', { bubbles: true }));
      });
    },
  });
});
