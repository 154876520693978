import { Controller } from '@hotwired/stimulus';
import MicroModal from 'micromodal';

export default class extends Controller {
  static targets = ['select'];
  declare selectTarget: HTMLSelectElement;

  /**
   * submit だとブラウザによっては Turbo が発火しない場合があるので、requestSubmit でサブミットさせる
   *
   * @param event
   */
  call(event: MouseEvent): void {
    const input = event.currentTarget as HTMLInputElement;

    if (this.selectTarget.value == 'owner') {
      const modalId = this.selectTarget.dataset.modal;
      MicroModal.show(modalId, {
        onClose: () => {
          input.form.reset();
        },
      });
    } else {
      input.form.requestSubmit();
    }
  }
}
