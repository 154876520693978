import { Controller } from '@hotwired/stimulus';
import Litepicker from 'litepicker';

export default class extends Controller {
  static targets = ['toggle', 'picker', 'input'];

  declare toggleTarget: HTMLInputElement;
  declare pickerTarget: HTMLInputElement;
  declare inputTarget: HTMLInputElement;

  declare picker;

  connect(): void {
    this.initDatePicker();
    this.initToggleChecked();
  }

  /**
   * datepicker の初期化
   */
  initDatePicker(): void {
    if (this.inputTarget.value) {
      this.pickerTarget.value = `${this.inputTarget.value}`;
    }

    this.picker = new Litepicker({
      element: this.pickerTarget,
      lang: 'ja-JP',
      setup: (picker) => {
        picker.on('selected', (fromDate) => {
          const fromValue = fromDate.dateInstance.toLocaleDateString('ja', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          });
          this.inputTarget.value = fromValue;
          this.inputTarget.dispatchEvent(new CustomEvent('change'));
        });
      },
    });
  }

  /**
   * 初期表示時 checked 設定
   */
  initToggleChecked(): void {
    if (this.inputTarget.value) {
      this.toggleTarget.checked = true;
      this.pickerTarget.disabled = false;
    } else {
      this.toggleTarget.checked = false;
      this.pickerTarget.disabled = true;
    }
  }

  /**
   * 関連する要素の活・非活制御
   */
  toggle(): void {
    if (this.toggleTarget.checked) {
      this.pickerTarget.disabled = false;
      this.picker.setDate(this.getDefaultDate());
    } else {
      this.clearDate();
      this.inputTarget.dispatchEvent(new CustomEvent('change'));
    }
  }

  /**
   * picker に関連する要素のクリア制御
   */
  clearDate(): void {
    this.pickerTarget.disabled = true;
    this.picker.clearSelection();
    this.pickerTarget.value = '';
    this.inputTarget.value = '';
  }

  /**
   * デフォルトの日付
   *
   * @return Date
   */
  private getDefaultDate(): Date {
    const daysAfter = this.pickerTarget.dataset.daysAfter ? Number(this.pickerTarget.dataset.daysAfter) : 0;
    const date = new Date();
    date.setDate(date.getDate() + daysAfter);
    return date;
  }
}
