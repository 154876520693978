import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'withinDate',
    'withinDateCheck',
    'within',
    'withinNumber',
    'withinNumberCheck',
    'withinNumberError',
  ];

  declare withinDateTarget: HTMLInputElement;
  declare withinDateCheckTarget: HTMLInputElement;
  declare withinTarget: HTMLInputElement;

  declare withinNumberTarget: HTMLInputElement;
  declare withinNumberCheckTarget: HTMLInputElement;
  declare withinNumberErrorTarget: HTMLInputElement;

  connect(): void {
    this.checkDate();

    this.withinNumberTarget?.addEventListener('input', () => {
      this.checkNumber();
    });
  }

  /**
   * 選択した日付から本日から120日以降の場合はバリデーションをかける
   */
  checkDate(): void {
    if (!this.withinDateTarget) return;
    const selectedDate = new Date(this.withinDateTarget?.value);
    const today = new Date();
    const maxAllowedDate = new Date(today.setDate(today.getDate() + 119));

    if (selectedDate > maxAllowedDate) {
      this.withinDateCheckTarget.value = '';
      this.withinTarget.classList.add('within_error');
    } else {
      this.withinDateCheckTarget.value = 'ok';
      this.withinTarget.classList.remove('within_error');
    }
  }

  /**
   * 入力値が120より大きい場合はバリデーションをかける
   */
  checkNumber(): void {
    if (!this.withinNumberTarget) return;
    const numberValue = Number(this.withinNumberTarget?.value);

    if (numberValue > 120) {
      this.withinNumberCheckTarget.value = '';
      this.withinNumberErrorTarget.classList.add('hidden');
    } else {
      this.withinNumberCheckTarget.value = 'ok';
      // デフォルトで表示されるエラーメッセージを非表示にする
      if (!this.withinNumberErrorTarget.innerHTML.includes('120')) {
        this.withinNumberErrorTarget.classList.remove('hidden');
      }
    }
  }
}
