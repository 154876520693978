import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field'];
  private minuteStep = [0, 15, 30, 45];
  private filterKeyword = '';
  private listElement = null;
  private startTabIndex = null;
  private closeLayer = null;

  declare fieldTarget: HTMLInputElement;

  connect(): void {
    if (this.element instanceof HTMLElement) {
      this.element.dataset.scopePath = 'stimulus/time_picker';
    }
    this.appendCloseLayer();
  }

  filter(event): void {
    const target = event.currentTarget;
    this.filterKeyword = target.value;
    this.buildTimeList();
  }

  focusList(event): void {
    if (event.code === 'ArrowDown') {
      this.focusDown(event);
      event.preventDefault();
      event.stopPropagation();
    } else if (event.code === 'ArrowUp') {
      this.focusUp(event);
      event.preventDefault();
      event.stopPropagation();
    } else if (event.code === 'Escape' || event.code == 'Tab') {
      this.destroyTimeList();
    }
  }

  showList(): void {
    this.fieldTarget.setSelectionRange(0, this.fieldTarget.value.length);
    this.buildTimeList();
  }

  format(): void {
    this.fieldTarget.value = this.fieldTarget.value.replace(/[０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    });
    this.fieldTarget.value = this.fieldTarget.value.replace(/^([0-9]{2})([0-9]{0,2})$/, '$1:$2');
    this.fieldTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }));
    this.filterKeyword = '';
  }

  private filterTimes(times: string[], keyword: string): string[] {
    keyword = keyword.replace(':', '');
    keyword = keyword.replace(/[０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    });
    return times.filter((v) => v.includes(keyword));
  }

  private times(): string[] {
    const times = [];
    if (this.fieldTarget.dataset.blankFlg == 'true') {
      times.push('');
    }
    for (let hour = 0; hour < 24; hour++) {
      this.minuteStep.forEach((minute) => {
        times.push(`${String(hour).padStart(2, '0')}${String(minute).padStart(2, '0')}`);
      });
    }
    if (this.addList24h()) {
      times.push('2400');
    }
    return times;
  }

  private destroyTimeList(): void {
    if (this.listElement) {
      this.element.removeChild(this.listElement);
      this.element.classList.remove('active');
      this.listElement = null;
      this.startTabIndex = null;
      this.closeLayer.style.display = 'none';
    }
  }

  private buildTimeList(): void {
    this.destroyTimeList();
    this.listElement = document.createElement('ul');
    let viewElement = null;
    this.filterTimes(this.times(), this.filterKeyword).forEach((time, index) => {
      const li = document.createElement('li');
      const button = document.createElement('button');
      const timeValue = time.replace(/^([0-9]{2})([0-9]{2})$/, '$1:$2');
      button.textContent = timeValue;
      button.addEventListener('click', this.setValue.bind(this));
      button.addEventListener('keydown', this.focusList.bind(this));
      button.tabIndex = index + 1;

      if (timeValue === this.fieldTarget.value) {
        viewElement = button;
        this.startTabIndex = button.tabIndex;
      }

      li.appendChild(button);
      this.listElement.appendChild(li);
    });
    this.element.appendChild(this.listElement);
    this.closeLayer.style.display = 'block';

    if (viewElement) {
      viewElement.scrollIntoView({ block: 'center' });
    }
  }

  private appendCloseLayer(): void {
    this.closeLayer = document.createElement('div');
    this.closeLayer.classList.add('close__layer');
    this.closeLayer.addEventListener('click', () => {
      this.destroyTimeList();
    });
    this.element.appendChild(this.closeLayer);
  }

  private setValue(event): void {
    const button = event.currentTarget;
    this.fieldTarget.value = button.textContent;
    this.fieldTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }));
    this.destroyTimeList();
    this.filterKeyword = '';
  }

  private focusDown(event): HTMLButtonElement | false {
    const tabindex = Number(event.target.getAttribute('tabindex') ?? this.startTabIndex);
    const nextTabindex = tabindex + 1;
    const nextButton = this.listElement.querySelector(`[tabindex="${nextTabindex}"]`);
    if (nextButton) {
      nextButton.focus();
      return nextButton;
    }
    return false;
  }

  private focusUp(event): HTMLButtonElement | false {
    const tabindex = Number(event.target.getAttribute('tabindex') ?? this.startTabIndex);
    const nextTabindex = Math.max(0, tabindex - 1);
    const nextButton = this.listElement.querySelector(`[tabindex="${nextTabindex}"]`);
    if (nextButton) {
      nextButton.focus();
      return nextButton;
    }
    return false;
  }

  /**
   * 曜日別の受付時間の終了時間および休憩時間の終了時間は24:00を選択可能にする
   */
  private addList24h(): boolean {
    const regex = [/\[end_time]/, /\[end_break_time]/];
    return regex.some((rgx) => rgx.test(this.fieldTarget.name));
  }
}
