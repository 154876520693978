import { Controller } from '@hotwired/stimulus';
import { navigator } from '@hotwired/turbo';
import { FrameElement } from 'types';
import 'src/ui_utils/single_date_picker';

export default class extends Controller {
  declare frameTarget: FrameElement;

  static targets = ['frame'];

  close(): void {
    this.frameTarget.src = '';
    this.frameTarget.innerHTML = null;
    const entriesPath = location.pathname.replace(/\/[^/]+$/, '');
    const url = location.origin + entriesPath;
    navigator.history.push(new URL(url));
  }
}
