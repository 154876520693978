import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['template', 'fields'];

  declare templateTarget: HTMLTemplateElement;
  declare fieldsTarget: HTMLElement;

  connect(): void {
    if (!this.fieldsTarget.querySelector('div')) {
      this.add();
    }
  }

  add(): void {
    // 新規追加する際に適当なインデックス数値で穴埋めする
    const surrogate = String(new Date().getTime());
    const clone = this.templateTarget.content.cloneNode(true) as HTMLElement;
    const input = clone.querySelector('input');
    input.name = input.name.replace('__NEW_INDEX__', surrogate);
    input.id = input.id.replace('__NEW_INDEX__', surrogate);
    this.fieldsTarget.appendChild(clone);
  }

  remove(event: MouseEvent): void {
    const button = event.currentTarget as HTMLButtonElement;
    const field = button.closest('.nested__fields') as HTMLElement;
    field.style.display = 'none';
    field.querySelector<HTMLInputElement>('input[type="hidden"]').value = '1';
  }
}
