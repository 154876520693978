import { Controller } from '@hotwired/stimulus';
import MicroModal from 'micromodal';

// MicroModal を開く
export default class extends Controller {
  static values = {
    modalId: String,
  };
  declare modalIdValue: string;

  connect(): void {
    MicroModal.show(this.modalIdValue);
  }
}
