import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['preview', 'closeLayer'];

  declare previewTarget: HTMLElement;
  declare closeLayerTarget: HTMLElement;

  showPreview(): void {
    this.previewTarget.classList.add('active');
    this.closeLayerTarget.classList.add('active');
  }

  hidePreview(): void {
    this.previewTarget.classList.remove('active');
    this.closeLayerTarget.classList.remove('active');
  }
}
