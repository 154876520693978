import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['webhookUrl', 'template'];

  declare webhookUrlTarget: HTMLInputElement;
  declare templateTarget: HTMLTemplateElement;

  validate() {
    const modal = document.querySelector('#notify__slack');
    const checkbox = document.querySelector('#book_board_setting_notify_slack') as HTMLInputElement;
    if (!checkbox.checked) {
      modal.classList.remove('is-open');
      return;
    }

    if (this.webhookUrlTarget.value) {
      modal.classList.remove('is-open');
    } else {
      return this.flashHtml();
    }
  }

  flashHtml() {
    const flash = document.querySelector('#flash-alerts') as HTMLElement;
    const content = this.templateTarget.content;
    flash.appendChild(document.importNode(content, true));
  }
}
