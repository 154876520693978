import { Controller } from '@hotwired/stimulus';
import Choices from 'choices.js';
import multiMeetingStyleOutletController from 'controllers/multi_meeting_style_select_controller';

type choiceData = { label: string; value: string; selected: string | boolean; customProperties: string };

/**
 * 担当者 UI に関する制御を行うコントローラ
 *
 * ### パラメータについて
 *
 * - meetingStyleValue : 会議形式(シングル)
 *   - 会議形式を変更した際に meeting_style_controller から直接変更される
 *   - 担当者リストの中で、選択された会議形式に対応可能な人だけ enable になる
 * - userAttendStyleValue : 担当者のアサイン方式
 *   - アサイン方式を変更した際に user_attend_style_controller から直接変更される
 *   - ビデオ会議 URL を発行する代表者設定 UI の表示をコントロールする
 *   - 全員出席の場合のみ、代表者の設定が必要
 * - meetingStyleSettingValue : 会議選択設定(シングル、マルチ)
 *   - 会議選択設定を変更した際に meeting_style_setting_controller から直接変更される
 *   - 担当者リストの中で、選択された設定内で選択された会議形式すべてに対応可能な人だけ enable になる
 * - multiMeetingStyleValue : 会議形式(マルチ)
 *   - 会議形式を変更した際に multi_meeting_style_select_controller から直接変更される
 *   - 担当者リストの中で、選択された会議形式すべてに対応可能な人だけ enable になる
 */
export default class extends Controller {
  static values = {
    meetingStyle: String,
    userAttendStyle: String,
    meetingStyleSetting: String,
    multiMeetingStyle: Array<string>,
  };

  static targets = ['user', 'userDateList', 'issuerBox', 'issuerSelector'];
  static outlets = ['multi-meeting-style-select'];
  declare multiMeetingStyleSelectOutlet: multiMeetingStyleOutletController;
  declare hasMultiMeetingStyleSelectOutlet: boolean;

  declare userTarget: HTMLSelectElement;
  declare userDateListTarget: HTMLDataListElement;
  declare userChoiceObj: Choices;
  declare issuerBoxTarget: HTMLElement;
  declare issuerSelectorTarget: HTMLSelectElement;
  declare issuerChoiceObj: Choices;
  declare meetingStyleSettingValue: 'single' | 'multi';
  declare meetingStyleValue: 'offline' | 'google_meet' | 'zoom' | 'teams' | 'other' | 'tel' | 'notice';
  declare userAttendStyleValue: 'all_attend' | 'random_single_attend';
  declare multiMeetingStyleValue: string[];
  private beforeChangeIssuerValue: string;

  private tooltip_text_web =
    '現在は選択することができません。切り替えるためには、別のメンバーをチームに招待して、その人自身が「アカウント設定画面」にて、Web会議ツールの連携作業を行う必要があります。';
  private tooltip_text_offline =
    '現在は選択することができません。切り替えるためには、別のメンバーをチームに招待する必要があります。';

  initialize(): void {
    this.initIssuerChoices();
    this.initUserChoices();
    this.searchOffChoicesInput();
    this.beforeChangeIssuerValue = this.issuerChoiceObj.getValue(true) as string;
  }

  connect(): void {
    // 担当者（同席者）の change イベントを走らせる（強制バリデート）（「再読み込み」でもイベントが発火するように setTimeout を使用）
    setTimeout(() => {
      this.userTarget.dispatchEvent(new Event('change'));
    }, 0);
  }

  /**
   * ユーザリストと URL 発行者リストを更新する
   * 画面初期表示時、ミーティングスタイル変更時、担当者の割り当て方法変更時
   */
  reset(): void {
    this.updateList();
    this.updateIssuerSelector();
    this.resetCalendarUser();
  }

  /**
   * ミーティングスタイルセッティングが変更時のリセット
   *
   * @param meetingStyleValue
   */
  resetMeetingStyleSetting(meetingStyleSettingValue): void {
    this.meetingStyleSettingValue = meetingStyleSettingValue;
    // 担当者（同席者）の change イベントを走らせる（強制バリデート）
    setTimeout(() => {
      this.userTarget.dispatchEvent(new Event('change'));
    }, 250);
  }

  /**
   * ミーティングスタイルが変更時のリセット
   *
   * @param meetingStyleValue
   */
  resetMeetingStyle(meetingStyleValue): void {
    this.meetingStyleValue = meetingStyleValue;
    // 担当者（同席者）の change イベントを走らせる（強制バリデート）
    setTimeout(() => {
      this.userTarget.dispatchEvent(new Event('change'));
    }, 250);
  }

  /**
   * マルチミーティングスタイル変更時のリセット
   *
   * @param meetingStyleValue
   */
  resetMultiMeetingStyle(multiMeetingStyleValue: string[]): void {
    this.multiMeetingStyleValue = multiMeetingStyleValue;
    // 担当者（同席者）の change イベントを走らせる（強制バリデート）
    setTimeout(() => {
      this.userTarget.dispatchEvent(new Event('change'));
    }, 250);
  }

  /**
   * 「全員を担当者にする」「一人を担当者にする」切り替え時のリセット
   *
   * @param userAttendStyleValue
   */
  resetUserAttendStyleValue(userAttendStyleValue): void {
    this.userAttendStyleValue = userAttendStyleValue;
    // 担当者（同席者）の change イベントを走らせる（強制バリデート）
    this.userTarget.dispatchEvent(new Event('change'));
  }

  /**
   * ビデオチャット URL の発行者を決める <select> の選択肢を更新
   * この発行者は「全員を担当者にする場合」のみ機能する
   */
  updateIssuerSelector(): void {
    // 現在の selected の値を抽出
    const selectedValue = this.issuerChoiceObj.getValue(true) as string;
    const issuerSelectorChoicesList = this.userList(true);

    for (let i = 0; i < issuerSelectorChoicesList.length; i++) {
      // selected が空の場合は先頭を selected にしてループを抜ける
      if (!selectedValue) {
        issuerSelectorChoicesList[i].selected = true;
        break;
      }
      issuerSelectorChoicesList[i].selected =
        selectedValue == issuerSelectorChoicesList[i].value || issuerSelectorChoicesList.length == 1;
    }

    this.issuerChoiceObj.clearStore(); // リストデータを全て空にする
    this.issuerChoiceObj.setChoices(issuerSelectorChoicesList, 'value', 'label', false); // リストデータを再設定
    this.issuerSelectorTarget.dispatchEvent(new Event('change')); // バリデートを走らせるための dispach
  }

  /**
   * 担当者（ホスト）で選択されたユーザーは、担当者（同席者）のリストから削除（※見た目上）
   * 見た目上にしている理由： 「担当者（同席者）」に対してのサーバー側の処理（通知等）を複雑化させたくない為
   */
  hideUserItem(): void {
    // 全員を担当者にする場合は「担当者（ホスト）」の selected を「担当者（同席者）」に追加
    if (this.userAttendStyleValue == 'all_attend') {
      this.updateList();
      this.resetCalendarUser();
    }

    const issuerSelectedValue = this.issuerChoiceObj.getValue(true) as string;
    const elements = document.getElementById('book_board_users').getElementsByClassName('choices__item');

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i] as HTMLElement;
      element.classList.remove('hide');
      if (this.userAttendStyleValue == 'all_attend' && issuerSelectedValue == element.dataset?.value) {
        element.classList.add('hide');
      }
    }
  }

  /**
   * 「その他メンバーの連携カレンダーに予定を自動登録」ドロップダウンリスト変更の為のカスタムイベントを発火
   */
  resetCalendarUser(): void {
    const customEvent = new CustomEvent('bookBoardUserUpdateList');
    window.dispatchEvent(customEvent);
  }

  allClear(event: InputEvent): void {
    const target = event.target as HTMLInputElement;
    const elem = document.getElementsByClassName('choices__inner')[1];
    if (target.checked) {
      this.userList().map((user) => {
        this.userChoiceObj.removeActiveItems(Number(user.value));
      });
      this.userChoiceObj.disable();
      elem.classList.add('choices_disabled');
    } else {
      this.userChoiceObj.enable();
      elem.classList.remove('choices_disabled');
    }
  }

  /**
   * 担当者(ホスト)に選ばれているユーザー全員が選択可能でない場所名の配列を生成し、multi-meeting-style-selectのupdateDisabledOptionの引数として渡して実行する
   * 例) Zoomと未連携のユーザーが担当者として選択されている場合、['zoom']という配列をupdateDisabledOptionに渡して実行する
   */
  setAvailableMeetingStyles(): void {
    if (!this.hasMultiMeetingStyleSelectOutlet) return;

    const selectedAttendees = this.userAttendStyleValue == 'all_attend' ? this.issuerSelectorTarget : this.userTarget;

    const allMeetingStyles = ['offline', 'google_meet', 'zoom', 'teams', 'other', 'tel', 'notice'];
    const availableMeetingStyles = Array.from(selectedAttendees.options).map((option) => {
      const parsedItem = JSON.parse(option.dataset.customProperties);
      return parsedItem.availableMettingStyles.split(' ');
    });
    const disabledItems = allMeetingStyles.filter((meeting_style) => {
      return !availableMeetingStyles.every((array) => array.includes(meeting_style));
    });
    this.multiMeetingStyleSelectOutlet.updateDisabledOption(disabledItems);
  }

  /**
   * ユーザリストを更新する
   *
   * ひとりを担当者にする形式の場合、選択したミーティング形式をサポートしたユーザのみが表示される
   * 例えば Zoom の場合は、Zoom 連携をしているユーザのみが表示される
   * また「ひとりを担当者にする」に切り替えの際、Choices.js 内部でミーティング形式をサポートしていないユーザーがリストから消されてしまう仕様の為
   * 「全員を担当者」に戻した場合に Choices.js のリストから消されたユーザーは Choices.js 化後のエレメントから拾えなくなくなってしまう・・
   * ので、元データとして用意した datalist のデータで再初期化、消えてしまったミーティング形式をサポートしていないユーザーを再選択可能にしています
   *
   * @private
   */
  private updateList() {
    const meeting_style_support = this.userAttendStyleValue === 'random_single_attend';
    const userList = this.userList(meeting_style_support).map((option) => {
      option.selected = this.userSelected(option.value);
      return option;
    });
    this.userChoiceObj.clearStore();
    this.userChoiceObj.setChoices(userList, 'value', 'label', false);
    this.beforeChangeIssuerValue = this.issuerChoiceObj.getValue(true) as string;
  }

  /**
   * ユーザリストの selected 返す
   *
   * @param optionValue
   * @private
   */
  private userSelected(optionValue): boolean {
    let selectedValues = this.userChoiceObj.getValue(true) as string[]; // 既に selected のユーザー

    // 担当者の割当方法「全員を担当者にする」が選択されている場合
    if (this.userAttendStyleValue === 'all_attend') {
      selectedValues = selectedValues.filter((value) => {
        // 変更前の担当者（ホスト）に含まれる selected は除外
        return this.beforeChangeIssuerValue != value;
      });

      const issuerSelectedValue = this.issuerChoiceObj.getValue(true) as string; // 担当者（ホスト）で selected のユーザー
      return selectedValues.includes(optionValue) || issuerSelectedValue == optionValue;
    } else {
      return selectedValues.includes(optionValue);
    }
  }

  /**
   * Choices.js 用のオリジンデータからユーザーリストを返す
   *
   * @param meeting_style_support
   * @private
   */
  private userList(meeting_style_support = false): choiceData[] {
    const options = meeting_style_support
      ? Array.from(this.userDateListTarget.options).filter((option) => {
          const styles = option.dataset.availableMettingStyles.split(' ');
          return this.checkStyleInclude(styles);
        })
      : Array.from(this.userDateListTarget.options);
    return options.map((option) => {
      return {
        label: option.dataset.label,
        value: option.dataset.value,
        selected: option.dataset.selected == 'true',
        customProperties: JSON.stringify({
          availableMettingStyles: option.dataset.availableMettingStyles,
          iconUrl: option.dataset.iconUrl,
        }),
      };
    });
  }

  /**
   * Choices.js で初期化後のエレメントからミーティングスタイルをサポートしているユーザーリストを返す
   *
   * @private
   */
  private mettingStyleSupportUserList(): HTMLOptionElement[] {
    const selectedOptions = Array.from(this.userTarget.options);
    return selectedOptions.filter((option) => {
      const customProperties = JSON.parse(option.dataset.customProperties);
      const availableMettingStyles = customProperties.availableMettingStyles;
      const styles = availableMettingStyles.split(' ');
      return this.checkStyleInclude(styles);
    });
  }

  /**
   * 担当者(ホスト) <select> に Choices.js ライブラリを適用
   *
   * @private
   */
  private initIssuerChoices(): void {
    this.issuerChoiceObj = new Choices(this.issuerSelectorTarget, {
      searchEnabled: false,
      searchChoices: false,
      removeItemButton: false,
      shouldSort: true,
      shouldSortItems: true,
      sorter: function (a, b) {
        return a.value - b.value;
      },
      placeholder: true,
      noChoicesText: '他に登録先の候補がありません',
      placeholderValue: 'メンバーを選択       ', // 文字数*バイト数で表示widthが決められていそうなので空白を付与している
      itemSelectText: '',
      // カスタムテンプレートの指定
      callbackOnCreateTemplates: function (template) {
        return {
          item: ({ classNames }, data) => {
            return template(`
              <div class="${classNames.item}" data-item data-id="${data.id}" data-value="${data.value}">
                ${
                  Object.keys(data.customProperties).length != 0 // 画面初期表示時には iconUrl が取れないので一旦ブランクを挿入
                    ? '<img width="24" height="24" class="user__icon" src="' +
                      JSON.parse(data.customProperties)?.iconUrl +
                      '" />'
                    : ''
                } ${data.label}
              </div>
            `);
          },
          choice: ({ classNames }, data) => {
            return template(`
              <div class="${classNames.item} ${classNames.itemChoice} ${classNames.itemSelectable}" data-choice data-choice-selectable data-id="${data.id}" data-value="${data.value}">
                ${
                  Object.keys(data.customProperties).length != 0 // 画面初期表示時には iconUrl が取れないので一旦ブランクを挿入
                    ? '<span><img width="24" height="24" class="user__icon" src="' +
                      JSON.parse(data.customProperties)?.iconUrl +
                      '" /></span>'
                    : ''
                } ${data.label}
              </div>
            `);
          },
        };
      },
    });
  }

  /**
   * 担当者(同席者) <select> に Choices.js ライブラリを適用
   * see: https://github.com/Choices-js/Choices/blob/master/src/scripts/templates.ts
   *
   * @private
   */
  private initUserChoices(): void {
    this.userChoiceObj = new Choices(this.userTarget, {
      searchEnabled: false,
      searchChoices: false,
      removeItemButton: true,
      shouldSort: true,
      shouldSortItems: true,
      sorter: function (a, b) {
        return a.value - b.value;
      },
      placeholder: true,
      noChoicesText: '他に登録先の候補がありません',
      placeholderValue: 'メンバーを選択       ', // 文字数*バイト数で表示widthが決められていそうなので空白を付与している
      itemSelectText: '',
      // カスタムテンプレートの指定
      callbackOnCreateTemplates: function (template) {
        return {
          item: ({ classNames }, data) => {
            return template(`
              <div class="${classNames.item}" data-item data-id="${data.id}" data-value="${data.value}">
                <img width="24" height="24" class="user__icon" src="${JSON.parse(data.customProperties)?.iconUrl}" /> ${data.label}
                <button type="button" class="choices__button" aria-label="Remove item: '${data.id}'" data-button="">Remove item</button>
              </div>
            `);
          },
          choice: ({ classNames }, data) => {
            return template(`
              <div class="${classNames.item} ${classNames.itemChoice} ${classNames.itemSelectable}" data-choice data-choice-selectable data-id="${data.id}" data-value="${data.value}">
                ${
                  JSON.parse(data.customProperties)?.iconUrl
                    ? '<span><img width="24" height="24" class="user__icon" src="' +
                      JSON.parse(data.customProperties)?.iconUrl +
                      '" /></span>'
                    : ''
                } ${data.label}
              </div>
            `);
          },
        };
      },
    });
    this.userChoiceObj.setChoices(this.userList(), 'value', 'label', false);
  }

  /**
   * Choices.js で生成された検索用の input を readonly にする
   *
   * @private
   */
  private searchOffChoicesInput(): void {
    const elements = document.querySelectorAll('input[type="search"]');
    elements.forEach((element: HTMLInputElement) => {
      element.readOnly = true;
    });
  }

  /**
   * 該当ユーザが担当者になれるかどうかを判定
   *
   * @param styles
   * @private
   */
  private checkStyleInclude(styles): boolean {
    if (this.meetingStyleSettingValue === 'multi') {
      if (!this.multiMeetingStyleValue.length) return false;
      const isInclude = this.multiMeetingStyleValue.every((style) => styles.includes(style));

      return isInclude;
    }

    return styles.includes(this.meetingStyleValue);
  }
}
