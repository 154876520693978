import { Controller } from '@hotwired/stimulus';
import { debounce } from 'debounce';

type FieldElement = HTMLInputElement | HTMLTextAreaElement;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function implementsFieldElement(arg: any): arg is FieldElement {
  return (
    arg !== null &&
    typeof arg === 'object' &&
    (arg instanceof HTMLInputElement || arg instanceof HTMLTextAreaElement || arg instanceof HTMLSelectElement)
  );
}

// フォームのバリデーション
export default class extends Controller {
  static targets = ['fields', 'checkboxes', 'errors', 'submit', 'intervalCheck', 'innerForm'];
  static classes = ['error'];

  private errors = {};
  /**
   * MEMO:
   * - グループ化されたエラーを管理するためのオブジェクト
   * - 本来は グループの概念もerrors に含めるべきだが、影響範囲が大きいため暫定的に別オブジェクトで管理している
   * Usage:
   * - data-validator-group-error="xxx": グルーピングさせたい、target = field を指定している任意の要素に付与する
   * - data-validator-group-error-receiver="yyy": 対応するグループエラーを受け取る要素に付与する
   */
  private errorGroup = {};

  declare fieldsTargets: FieldElement[];
  declare checkboxesTargets: HTMLInputElement[];
  declare errorsTargets: HTMLElement[];
  declare intervalCheckTargets: FieldElement[];
  declare errorClass: string;
  declare submitTarget: FieldElement | HTMLButtonElement;
  declare innerFormTargets: HTMLElement[];

  /**
   * validate アクションを debounce にする
   * input イベントで実行するため都度実行にすると負荷が高いため
   */
  initialize(): void {
    this.validate = debounce(this.validate, 250).bind(this);
  }

  async connect(): Promise<void> {
    // 画面表示時のバリデーションチェック
    // submit ボタンを有効化するかどうかに使う
    for (const field of this.fieldsTargets) {
      await this.validateToSelfAndChildren(field);
    }
    // 親子関係にある項目をチェックするかどうかの判定
    this.fieldsTargets.forEach((field) => {
      if (field.dataset.parent) {
        this.parentActivity(field) ? this.recheck(field) : this.ignoreCheck(field);
      } else if (field.dataset.secondParent) {
        this.secondParentActivity(field) ? this.recheck(field) : this.ignoreCheck(field);
      }
    });
    this.intervalValidate();
    this.syncGroupCheckbox();
    this.toggleSubmit();
  }

  /**
   * 有効であるときにバリデーションを行う（無効のときはエラーを削除する）
   *
   * @param event
   */
  async validateIfEnabled(event: InputEvent): Promise<void> {
    const field = event.currentTarget || event.target;
    if (!implementsFieldElement(field)) {
      return;
    }

    const field_key = field.dataset.validatorFieldKey;
    if (!field_key) {
      return;
    }

    const elements = document.getElementsByClassName('js-validate-enabled' + field_key);
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i] as HTMLInputElement;
      if (element.checked) {
        this.validate(event);
        return;
      } else {
        this.removeError(field);
        this.displayError(field);
        this.toggleSubmit();
        return;
      }
    }
  }

  /**
   * 最大文字数チェック（無効のときはエラーを削除する）
   *
   * @param event
   */
  async validateMaxLength(event: InputEvent): Promise<void> {
    const field = event.currentTarget || event.target;
    if (!implementsFieldElement(field)) {
      return;
    }

    const max_length = field.dataset.validatorMaxLength;
    if (!max_length) {
      return;
    }

    /**
     * FireFox does not support
     * see: https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Intl/Segmenter
     *
     * type errorはtypescriptのバージョンを上げれば治るかもしれない
     * see: https://github.com/denoland/deno/issues/14182
     */
    // FIXME: 直す
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const SEGMENTER = new Intl.Segmenter('ja', { granularity: 'grapheme' });
    const segments = SEGMENTER.segment(field.value);
    const value_length = Array.from(segments).length;

    const valid = value_length <= Number(max_length);
    if (valid) {
      this.removeError(field);
    } else {
      this.addError(field, field.dataset.validatorCustomErrorMessages);
    }

    this.displayError(field);
    this.toggleSubmit();
  }

  /**
   * 有効にしたときに各項目に対してバリデーションを行う（無効にしたときはエラーを削除する）
   *
   * @param event
   */
  async validateChildrenIfEnabled(event: InputEvent): Promise<void> {
    const field = event.currentTarget || event.target;
    if (!implementsFieldElement(field)) {
      return;
    }

    const field_key = field.dataset.validatorFieldKey;
    if (!field_key) {
      return;
    }

    const elements = document.getElementsByClassName('js-validate' + field_key);
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i] as FieldElement;
      if ((field as HTMLInputElement).checked) {
        await this.validateToSelfAndChildren(element);
      } else {
        this.removeError(element);
      }

      this.displayError(element);
      this.toggleSubmit();
    }
  }

  async validate(event: InputEvent | FocusEvent): Promise<void> {
    const field = event.currentTarget || event.target;
    if (!implementsFieldElement(field)) {
      return;
    }

    await this.validateToSelfAndChildren(field);
    this.displayError(field);
    this.toggleSubmit();
  }

  // debounce 制限を無視したバリデーション
  async localValidate(event: InputEvent | FocusEvent): Promise<void> {
    const field = event.currentTarget || event.target;
    if (!implementsFieldElement(field)) {
      return;
    }

    this.checkRuleByLocal(field);
    this.displayError(field);
    this.toggleSubmit();
  }

  /**
   * グループ化されたチェックボックス専用のバリデートアクション
   *
   * @param event
   */
  async validateGroupCheckbox(event: InputEvent): Promise<void> {
    await this.validate(event);
    this.syncGroupCheckbox();
  }

  /**
   * 遅延実行バリデートアクション
   *
   * validate が debounce で制限されているため他と同時に実行することができない(250msの待ち)
   * 他で実行されている validate と重複しないよう delay を dataset に設定し実行する
   *
   * @param event
   */
  async delayValidate(event: InputEvent): Promise<void> {
    const field = event.target as FieldElement;
    const delay = field.dataset.validatorDelay as string;
    if (delay) {
      await new Promise((resolve) => setTimeout(resolve, Number(delay)));
    }
    await this.validate(event);
  }

  /**
   * event に紐づくエラーメッセージを削除する
   *
   * @param event
   */
  async removeErrorBy(event): Promise<void> {
    const field = event.currentTarget || event.target;

    this.removeError(field);
    this.toggleSubmit();
  }

  /**
   * 更新ボタンのアクティブをトグルする
   * エラーがある、又は入力途中の入れ子のフォームが場合は無効化、なければ有効化する
   * 入れ子のフォームで追加されていないものがあれば無効化する
   *
   */
  toggleSubmit(): void {
    if (this.hasErrors() || this.hasUncompletedNestedForm()) {
      this.disableSubmit();
    } else {
      this.enableSubmit();
    }
  }

  /**
   * グループ化されたチェックボックスの required 属性を同期させる
   * 同じグループのチェックボックス、どれか一つでもチェックが入っていれば OK なので以下のルールで制御する
   *
   * - checked なチェックボックスが一つもない場合は、全てに required を付与する
   * - checked なチェックボックスにのみ required を付与する
   *
   * @private
   */
  private syncGroupCheckbox(): void {
    const checkboxesState = {};
    this.checkboxesTargets.forEach((checkbox) => {
      checkboxesState[checkbox.name] ||= checkbox.checked ? true : undefined;
    });
    this.checkboxesTargets.forEach((checkbox) => {
      checkbox.required = !checkboxesState[checkbox.name] || checkbox.checked;
    });
  }

  /**
   * error があるかどうか判定する
   *
   * @private
   */
  private hasErrors(): boolean {
    return Object.values(this.errors).filter(Boolean).length > 0;
  }

  /**
   * 入力内容が追加されていない入れ子のフォームがあるか判定する
   *
   * @private
   */
  private hasUncompletedNestedForm(): boolean {
    if (this.innerFormTargets.length === 0) {
      return false;
    }
    const isAnyInnerFormVisible = this.innerFormTargets.some((form) => !form.classList.contains('hidden'));
    return isAnyInnerFormVisible;
  }

  /**
   * 自分自身と子要素のバリデーションを実行する
   *
   * @param field
   * @private
   */
  private async validateToSelfAndChildren(field: FieldElement): Promise<void> {
    if (this.skipValidation(field)) {
      this.propsToChildren(field);
      return;
    }
    await this.checkRule(field);
    this.propsToChildren(field);
  }

  /**
   * 指定された要素を定期的にバリデーションチェックする
   * 前の値を違う場合はバリデーション、同じ値の場合はスルーする
   *
   * ブラウザの自動入力機能を使った場合、イベントが発火しないのでこれを利用する
   *
   * @private
   */
  private intervalValidate(): void {
    this.intervalCheckTargets.forEach((field) => {
      const tryValidate = (field) => {
        let value = field.value;
        return async (field) => {
          if (value != field.value) {
            await this.validateToSelfAndChildren(field);
            this.displayError(field);
            this.toggleSubmit();
            value = field.value;
          }
        };
      };
      const tryValidateFunc = tryValidate(field);
      setInterval(() => {
        tryValidateFunc(field);
      }, 500);
    });
  }

  /**
   * 対象要素がバリデーションをスキップしていいかの判定をする
   *
   * checkbox の場合、同名の checkbox で他にチェックが入っていれば問題ないのでスキップする
   *
   * @param field
   * @private
   */
  private skipValidation(field: FieldElement): boolean {
    if (field.type === 'checkbox') {
      return this.siblings(field).some((sibling) => sibling.checked && field != sibling);
    }
    return false;
  }

  /**
   * 対象要素のバリデーションを実行する
   *
   * バリデーションはサーバサイドとローカルで実行する
   * サーバサイドでエラーになった場合は、ローカルでの実行はしない
   * （エラー情報が上書きされてしまうため）
   *
   * @param field
   * @private
   */
  private async checkRule(field: FieldElement): Promise<void> {
    if (field.dataset.validateEndpoint) {
      const result = await this.checkRuleByServer(field);
      if (!result) {
        return;
      }
    }
    this.checkRuleByLocal(field);
  }

  /**
   * data-validate-endpoint にバリデーションを依頼し、エラー表示の制御をする
   *
   * @param field
   * @private
   */
  private async checkRuleByServer(field: FieldElement): Promise<boolean> {
    const endpoint = field.dataset.validateEndpoint;
    const data = {
      [field.name]: field.value,
    };
    const result = await fetch(endpoint, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const json = await result.json();
    if (json.result === 'ok') {
      this.removeError(field);
      return true;
    } else {
      this.addError(field, json.error_message);
      return false;
    }
  }

  /**
   * HTML API でバリデーションを実行し、エラー表示の制御をする
   *
   * @param field
   * @private
   */
  private checkRuleByLocal(field: FieldElement): void {
    const valid = field.checkValidity();
    if (valid) {
      this.removeError(field);
    } else {
      this.addError(field, field.dataset.validatorCustomErrorMessages);
    }
  }

  /**
   * errors ステートにエラーメッセージを格納する
   *
   * @param field
   * @param message
   * @private
   */
  private addError(field: FieldElement, message?: string): void {
    this.errors[field.name] = message || field.validationMessage;

    if (field.dataset.validatorGroupError) {
      if (!this.errorGroup[field.dataset.validatorGroupError]) {
        this.errorGroup[field.dataset.validatorGroupError] = {};
      }
      this.errorGroup[field.dataset.validatorGroupError][field.name] = message || field.validationMessage;
    }
  }

  /**
   * errors ステートからエラーメッセージを削除する
   *
   * @param field
   * @private
   */
  private removeError(field: FieldElement): void {
    delete this.errors[field.name];

    if (field.dataset.validatorGroupError) {
      if (!this.errorGroup[field.dataset.validatorGroupError]) {
        return;
      }
      delete this.errorGroup[field.dataset.validatorGroupError][field.name];

      // MEMO: グループ化されたエラーがなくなったら、エラーグループ自体を削除する
      if (Object.keys(this.errorGroup[field.dataset.validatorGroupError]).length === 0) {
        delete this.errorGroup[field.dataset.validatorGroupError];
      }
    }
  }

  /**
   * 対象要素と子要素にエラーデータがあれば、それをエラー表示をする
   * もしなければエラー表示をリセットする
   *
   * @param field
   * @private
   */
  private displayError(field): void {
    if (this.errors[field.name]) {
      this.showErrorMessage(field);
    } else {
      this.hideErrorMessage(field);
    }

    // グループ化されたエラーの制御
    if (this.errorGroup[field.dataset.validatorGroupError]) {
      this.addGroupErrorClass(field);
    } else {
      this.removeGroupErrorClass(field);
    }

    this.children(field).forEach((children) => {
      this.displayError(children);
    });
  }

  /**
   * 概要要素のエラー表示をする
   *
   * @param field
   * @private
   */
  private showErrorMessage(field: FieldElement): void {
    field.classList.add(this.errorClass);
    const errorElement = this.errorElement(field);
    errorElement.textContent = this.errors[field.name];
    errorElement.classList.add('show');
  }

  /**
   * 該当要素のエラー表示を消す
   *
   * @param field
   * @private
   */
  private hideErrorMessage(field: FieldElement): void {
    field.classList.remove(this.errorClass);
    const errorElement = this.errorElement(field);
    errorElement.textContent = '';
    errorElement.classList.remove('show');
  }

  /**
   * ターゲット要素に、エラークラスを付与する
   *
   * @param field
   * @returns
   */
  private addGroupErrorClass(field: FieldElement): void {
    const elements = document.querySelectorAll(
      `[data-validator-group-error-receiver="${field.dataset.validatorGroupError}"]`,
    );
    if (!elements) return;

    elements.forEach((element) => {
      element.classList.add('invalid');
    });
  }

  /**
   * ターゲット要素の、エラークラスを削除する
   *
   * @param field
   * @returns
   */
  private removeGroupErrorClass(field: FieldElement): void {
    const elements = document.querySelectorAll(
      `[data-validator-group-error-receiver="${field.dataset.validatorGroupError}"]`,
    );
    if (!elements) return;

    elements.forEach((element) => {
      element.classList.remove('invalid');
    });
  }

  /**
   * 該当要素のエラー表示要素を探して返す
   *
   * data-validator-target="errors"
   * data-field-name="要素名"
   *
   * @param field
   * @private
   */
  private errorElement(field): HTMLElement {
    return this.errorsTargets.find((error) => error.dataset.fieldName === field.name);
  }

  /**
   * 指定要素の親・先祖を探索しアクティブかどうかを判定する
   *
   * 直属の親要素がアクティブでも、さらに親要素が非アクティブだった場合はツリー構造上
   * 関連する全ての子要素がチェックしない対象となるため、必要であれば一番上まで探索する
   *
   * @param field
   * @private
   */
  private parentActivity(field: FieldElement): boolean {
    const [name, value] = field.dataset.parent.split('#');
    const parent = this.fieldsTargets.find((field) => field.name === name && field.value === value);
    if (!parent) {
      return false;
    }
    if (['radio', 'checkbox'].includes(parent.type) && parent instanceof HTMLInputElement && !parent.checked) {
      return false;
    }
    if (parent.dataset.parent) {
      return this.parentActivity(parent);
    } else if (parent.dataset.secondParent) {
      return this.secondParentActivity(parent);
    } else {
      return true;
    }
  }

  /**
   * 指定要素の親・先祖を探索しアクティブかどうかを判定する
   *
   * 直属の親要素がアクティブでも、さらに親要素が非アクティブだった場合はツリー構造上
   * 関連する全ての子要素がチェックしない対象となるため、必要であれば一番上まで探索する
   *
   * @param field
   * @private
   */
  private secondParentActivity(field: FieldElement): boolean {
    const [name, value] = field.dataset.secondParent.split('#');
    const parent = this.fieldsTargets.find((field) => field.name === name && field.value === value);
    if (!parent) {
      return false;
    }
    if (['radio', 'checkbox'].includes(parent.type) && parent instanceof HTMLInputElement && !parent.checked) {
      return false;
    }
    if (parent.dataset.parent) {
      return this.parentActivity(parent);
    } else if (parent.dataset.secondParent) {
      return this.secondParentActivity(parent);
    } else {
      return true;
    }
  }

  /**
   * 子要素に親要素の変化を伝える
   *
   * 子要素は親要素の状態に応じて、バリデーションをしたり、リセットしたりする
   *
   * @param parentField
   * @private
   */
  private propsToChildren(parentField: FieldElement): void {
    const siblings = this.siblings(parentField as HTMLInputElement);
    siblings.forEach((field) => {
      const children = this.children(field);
      children.forEach((children) => {
        this.reinitialize(field, children);
      });
    });
  }

  /**
   * 指定子要素を、依存している親要素の状態に応じて再度初期化する
   *
   * もし親要素がアクティブでなくなったら、子要素はバリデーション対象ではなくなる
   * 逆にアクティブになったら、子要素はバリデーションチェックをされる
   * さらに、子要素自身が他の要素の親であることもあるので、再起チェックを行う
   *
   * @param parentField
   * @param children
   * @private
   */
  private reinitialize(parentField: FieldElement, children: FieldElement): void {
    if (['radio', 'checkbox'].includes(parentField.type) && parentField instanceof HTMLInputElement) {
      if (parentField.checked) {
        this.recheck(children);
      } else {
        this.ignoreCheck(children);
      }
    } else if (['select-multiple'].includes(parentField.type)) {
      const options = (parentField as unknown as HTMLSelectElement).options;
      if (this.optionsValueCheck(options, this.selectParent(parentField, children))) {
        this.recheck(children);
      } else {
        this.ignoreCheck(children);
      }
    } else {
      const regexValue = new RegExp(`^.+#${parentField.value}$`);
      if (regexValue.test(this.selectParent(parentField, children))) {
        this.recheck(children);
      } else {
        this.ignoreCheck(children);
      }
    }
  }

  /**
   * 親要素がアクティブになったので、自身と自身の子要素もチェックする
   *
   * @param children
   * @private
   */
  private async recheck(children: FieldElement): Promise<void> {
    children.required = true;
    await this.validateToSelfAndChildren(children);
  }

  /**
   * 親要素がアクティブでは無くなったので、自身と自身の子要素のチェックを無視する
   *
   * @param children
   * @private
   */
  private ignoreCheck(children: FieldElement) {
    children.required = false;
    this.removeError(children);
    this.children(children).forEach((descendant) => {
      descendant.required = false;
      this.removeError(descendant);
    });
  }

  /**
   * 同じ name の兄弟要素を探して返す
   *
   * @param parentField 指定の要素
   * @returns 指定 field 自身を含む兄弟要素
   * @private
   */
  private siblings(parentField: FieldElement): HTMLInputElement[] {
    if (['radio', 'checkbox'].includes(parentField.type)) {
      return this.fieldsTargets.filter((field) => field.name === parentField.name) as HTMLInputElement[];
    } else {
      return [parentField] as HTMLInputElement[];
    }
  }

  /**
   * 依存している指定要素値(要素名#値)を返す
   *
   * data-parent="parent-input-name#value" という 要素名#値 というフォーマット
   * data-second-parent="parent-input-name#value" という 要素名#値 というフォーマット
   *
   * @param parentField 指定の要素
   * @returns 依存している指定要素値(要素名#値)
   * @private
   */
  private selectParent(parentField: FieldElement, children: FieldElement): string {
    if (!parentField.dataset.parentType) {
      return children.dataset?.parent;
    } else {
      return children.dataset?.secondParent;
    }
  }

  /**
   * 指定要素に依存している子供要素を探して返す
   *
   * data-parent="parent-input-name#value" という 要素名#値 というフォーマット
   * 指定した要素が指定した値になった時に、バリデーションを実行するという依存関係を作る
   *
   * radio / checkbox / select は同じ要素名で複数の値が存在するので、要素名と値が一致するか判定をする
   *
   * @param parentField 指定の親要素
   * @returns 子供の要素
   * @private
   */
  private children(parentField: FieldElement): FieldElement[] {
    const regexName = new RegExp(`^${this.escapeRegExp(parentField.name)}#.*$`);
    const regexValue = new RegExp(`^.+#${this.escapeRegExp(parentField.value)}$`);
    const relative = parentField.dataset?.relative; // 要素名が同じであれば子要素としたい場合に入れる

    if (!relative && ['radio', 'checkbox'].includes(parentField.type)) {
      const condition = (children) =>
        regexName.test(this.selectParent(parentField, children)) &&
        regexValue.test(this.selectParent(parentField, children));
      return this.fieldsTargets.filter(condition);
    } else {
      const condition = (children) => regexName.test(this.selectParent(parentField, children));
      return this.fieldsTargets.filter(condition);
    }
  }

  /**
   * 更新ボタンを無効化
   *
   * @private
   */
  private disableSubmit(): void {
    this.submitTarget.disabled = true;
  }

  /**
   * 更新ボタンを有効化
   *
   * @private
   */
  private enableSubmit(): void {
    this.submitTarget.disabled = false;
  }

  /**
   * regex オブジェクトを作る際に文字列をエスケープする
   *
   * @param string
   * @private
   */
  private escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  /**
   * select-multipleが依存している親要素の状態に応じて再度初期化するかどうかを判定する
   *
   * paramのparentはdata-parent="parent-input-name#value" という 要素名#値 というフォーマット
   *
   * @param options select-multipleのoptions
   * @param parent 指定の要素名#値
   * @private
   */
  private optionsValueCheck(options: HTMLOptionsCollection, parent: string) {
    for (let i = 0; i < options.length; i++) {
      const regexValue = new RegExp(`^.+#${options[i].value}$`);
      if (regexValue.test(parent)) {
        return true;
      }
    }

    return false;
  }
}
