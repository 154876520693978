import { Controller } from '@hotwired/stimulus';
import { debounce } from 'debounce';

// input 要素を非同期でデータ送信する
export default class extends Controller {
  static values = {
    endpoint: String,
  };
  static targets = ['form', 'message'];
  static classes = ['loading', 'success', 'fail'];

  declare endpointValue: string;
  declare successClass: string;
  declare failClass: string;
  declare loadingClass: string;
  declare formTarget: HTMLFormElement;
  declare messageTarget: HTMLElement;

  initialize(): void {
    this.sync = debounce(this.sync, 250).bind(this);
  }

  async sync(): Promise<void> {
    this.setLoadingClass();

    const formData = new FormData(this.formTarget);
    formData.set('_method', 'put');

    const csrfToken = document.querySelector<HTMLMetaElement>('[name="csrf-token"]').content;

    const result = await fetch(this.endpointValue, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': csrfToken,
      },
      body: formData,
    });
    const body = await result.json();

    if (result.status === 200 && body.status === 'success') {
      this.setSuccessClass();
    } else {
      this.messageTarget.textContent = '自動保存に失敗しました';
      this.messageTarget.classList.add('alert');
      setTimeout(() => {
        this.messageTarget.classList.remove('alert');
        this.messageTarget.textContent = '';
      }, 3000);
      this.setFailClass();
    }
  }

  private setLoadingClass(): void {
    if (this.successClass) {
      this.element.classList.remove(this.successClass);
    }
    if (this.failClass) {
      this.element.classList.remove(this.failClass);
    }
    if (this.loadingClass) {
      this.element.classList.add(this.loadingClass);
    }
  }

  private setSuccessClass(): void {
    if (this.loadingClass) {
      this.element.classList.remove(this.loadingClass);
    }
    if (this.successClass) {
      this.element.classList.add(this.successClass);
    }
  }

  private setFailClass(): void {
    if (this.loadingClass) {
      this.element.classList.remove(this.loadingClass);
    }

    if (this.failClass) {
      this.element.classList.add(this.failClass);
    }
  }
}
