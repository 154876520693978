import { Controller } from '@hotwired/stimulus';
import bookBoardUsersOutletController from 'controllers/book_board_users_controller';

export default class extends Controller {
  static outlets = ['book-board-users'];
  declare bookBoardUsersOutlet: bookBoardUsersOutletController;
  declare readonly hasBookBoardUsersOutlet: boolean;

  /**
   * 担当者の割り当て方法を変更したら、ユーザリストの data attr を更新する
   *
   * @param event
   */
  changeStyle(event: MouseEvent): void {
    const target = event.currentTarget as HTMLInputElement;
    if (!target.checked) {
      return;
    }

    if (this.hasBookBoardUsersOutlet) {
      // outlet では直接プロパティを変更できないので引数で渡す
      this.bookBoardUsersOutlet.resetUserAttendStyleValue(target.value);
    }
  }
}
