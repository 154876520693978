import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    contentId: String,
  };

  declare contentIdValue: string;

  show(): void {
    window.Appcues.show(this.contentIdValue);
  }
}
