import { Controller } from '@hotwired/stimulus';

/**
 * switch (radio/checkbox) にチェックが入っていると Input パーツが enable、入っていないと disable
 *
 * ### パーツ
 *
 * - switch : 表示を操作するスイッチ radio/checkbox ボタン
 * - input : 対象の Input パーツ (複数)
 */
export default class extends Controller {
  static targets = ['switch', 'input', 'select'];

  declare switchTarget: HTMLInputElement;
  declare inputTargets: HTMLInputElement[];
  declare selectTarget: HTMLSelectElement;
  declare hasSelectTarget: boolean;

  connect(): void {
    this.inputTargets.forEach((input) => {
      input.disabled = !this.switchTarget.checked;
    });
    if (this.hasSelectTarget) this.selectTarget.disabled = !this.switchTarget.checked;
  }

  toggle(): void {
    this.inputTargets.forEach((input) => {
      input.disabled = !this.switchTarget.checked;
    });
    if (this.hasSelectTarget) this.selectTarget.disabled = !this.switchTarget.checked;
  }
}
