import { Controller } from '@hotwired/stimulus';

// <select> で用意している値以外にカスタム入力欄に切り替える
export default class extends Controller {
  static targets = ['select', 'input'];

  declare selectTarget: HTMLSelectElement;
  declare inputTarget: HTMLInputElement;

  connect(): void {
    if (this.selectTarget.value != 'custom') {
      for (let i = 0; i < this.selectTarget.options.length; i++) {
        if (this.selectTarget.options[i].text === this.inputTarget.value) {
          this.selectTarget.options[i].selected = true;
        }
      }
    }
    this.toggleInputParts();
  }

  selectValue(): void {
    this.toggleInputParts();
    this.setValue();
  }

  toggleInputParts(): void {
    this.inputTarget.style.display = this.selectTarget.value === 'custom' ? 'inline-block' : 'none';
  }

  setValue(): void {
    if (this.selectTarget.value !== 'custom') {
      const optionsIndex = this.selectTarget.selectedIndex;
      this.inputTarget.value = this.selectTarget.options[optionsIndex].text;
      this.inputTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }));
    }
  }
}
