import { Controller } from '@hotwired/stimulus';
import MicroModal from 'micromodal';

export default class extends Controller {
  static targets = ['modal'];
  declare modalTargets: HTMLElement[];

  private mo: MutationObserver;

  connect(): void {
    MicroModal.init();

    const saveSubmitButton = document.getElementById('save-submit') as HTMLButtonElement;
    this.mo = new MutationObserver(() => {
      this.toggle();
    });

    const config = {
      attributes: true, //「属性」の変化を検知するオプション
    };

    // 「保存」ボタンの状態監視の開始
    this.mo.observe(saveSubmitButton, config);

    this.toggle();
  }

  disconnect(): void {
    //監視の終了
    this.mo.disconnect();
  }

  /**
   * 「保存」ボタンの disable に応じてモーダル内の submit の活・非活、注釈の表示切り替えを行う
   */
  private toggle(): void {
    const saveSubmitButton = document.getElementById('save-submit') as HTMLButtonElement;

    this.modalTargets.forEach((modal) => {
      const modalSubmitButton = modal.getElementsByTagName('button')['redirect_to_plan'] as HTMLButtonElement;
      const noteEnable = modal.getElementsByClassName('noteEnable')[0] as HTMLElement;
      const noteDisable = modal.getElementsByClassName('noteDisable')[0] as HTMLElement;

      modalSubmitButton.disabled = saveSubmitButton.disabled;

      if (saveSubmitButton.disabled) {
        noteEnable.style.display = 'none';
        noteDisable.style.display = 'block';
      } else {
        noteEnable.style.display = 'block';
        noteDisable.style.display = 'none';
      }
    });
  }
}
