import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /**
   * data-linked-buttonにセレクタを指定すると、指定されたセレクタのクリックイベントが起動する
   *
   * @param event
   */
  click(event: Event): void {
    if (!(event.currentTarget instanceof HTMLElement)) {
      return;
    }
    const currentButton = event.currentTarget;
    const linkedButton = currentButton.dataset.linkedButton;
    document.querySelector<HTMLElement>(linkedButton).click();
  }
}
