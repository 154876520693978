import { Controller } from '@hotwired/stimulus';

/**
 * ブックボード編集画面でメールのメッセージ入力時にプレビューの該当テキストをハイライトする
 */
export default class extends Controller {
  static values = {
    targetId: String,
  };
  declare targetIdValue: string;

  show(): void {
    //alert(this.messagePreviewIdValue);
    // FIXME: 直す
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const em = document.getElementById(this.targetIdValue).contentWindow.document.getElementById('target_preview');
    if (!em.classList.contains('show_message_preview')) {
      em.classList.add('show_message_preview');
    }
  }

  hide(): void {
    // FIXME: 直す
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const em = document.getElementById(this.targetIdValue).contentWindow.document.getElementById('target_preview');
    if (em.classList.contains('show_message_preview')) {
      em.classList.remove('show_message_preview');
    }
  }
}
