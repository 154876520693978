import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'select', 'emails', 'role'];

  declare inputTarget: HTMLInputElement;
  declare selectTarget: HTMLSelectElement;
  declare emailsTarget: HTMLInputElement;
  declare roleTarget: HTMLInputElement;

  passInputValue() {
    this.emailsTarget.value = this.inputTarget.value;
    this.roleTarget.value = this.selectTarget.value;
  }
}
