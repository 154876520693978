import { Controller } from '@hotwired/stimulus';

type FieldElement = HTMLInputElement;

export default class extends Controller {
  static targets = [
    'fields',
    'forms',
    'lists',
    'submits',
    'errors',
    'templateAttendeeList',
    'templateAttendeeForm',
    'addedAttendeesFormsArea',
    'addedAttendeesListsArea',
    'addAnotherAttendee',
  ];

  private errors = {};

  declare fieldsTargets: FieldElement[];
  declare formsTargets: HTMLElement[];
  declare listsTargets: HTMLElement[];
  declare submitsTargets: HTMLButtonElement[];
  declare errorsTargets: HTMLSpanElement[];
  declare templateAttendeeListTarget: HTMLTemplateElement;
  declare templateAttendeeFormTarget: HTMLTemplateElement;
  declare addedAttendeesFormsAreaTarget: HTMLElement;
  declare addedAttendeesListsAreaTarget: HTMLElement;
  declare addAnotherAttendeeTarget: HTMLButtonElement;

  newForm() {
    if (this.overLimitAttendee()) {
      return alert(
        '同席者の上限は9名です。現在同席者に設定している人を「×」で削除することにより、新たな同席者を設定できるようになります。',
      );
    }
    this.newInputForm();
    this.disabledNewFormButton();
  }

  add() {
    this.displayListAddedAttendees();
    this.hideAllForms();
    this.enabledNewFormButton();
  }

  clear(event: MouseEvent) {
    const currentButton = event.currentTarget as HTMLElement;
    const currentForm = currentButton.closest('.form__fields__attendees') as HTMLElement;
    currentForm.remove();
    this.enabledNewFormButton();
  }

  delete(event: MouseEvent) {
    this.removeList(event);
    this.removeForm(event);
    this.resetIndex();
  }

  validate() {
    this.toggleSubmit();
  }

  hideAllForms() {
    this.formsTargets.forEach((form: HTMLElement) => {
      form.classList.add('hidden');
    });
  }

  /**
   * 同席者情報入力フォームを新規作成する
   *
   * @private
   */
  private newInputForm() {
    const clone = this.templateAttendeeFormTarget.content.cloneNode(true) as HTMLElement;
    this.allowNullableCompanyName(clone);
    this.addedAttendeesFormsAreaTarget.appendChild(clone);
    this.attendeeIndex();
  }

  /**
   * 何人目の同席者であるかのindexを持たせる
   * @private
   */
  private attendeeIndex() {
    this.fieldsTargets.forEach((field) => {
      field.name = this.replaceFieldName(field.name);
    });
    this.errorsTargets.forEach((errorField) => {
      errorField.dataset.fieldName = this.replaceFieldName(errorField.dataset.fieldName);
    });
  }

  /**
   * name属性やクラス名にindexを付与する
   * e.g.  entry[attendees_fields[][__bookrun_attendee_name]] → entry[attendees_fields[index][__bookrun_attendee_name]]   * name属性を書き換える
   *
   * @param fieldName
   * @private
   */
  private replaceFieldName(fieldName: string): string {
    return fieldName.replace('[]', `[${this.formLength()}]`);
  }

  /**
   * 同席者数が上限を超えているかを判定する
   *
   * @private
   */
  private overLimitAttendee(): boolean {
    return this.formLength() + 1 > 9;
  }

  /**
   * 同席者情報を一覧表示する
   *
   * @private
   */
  private displayListAddedAttendees() {
    const clone = this.templateAttendeeListTarget.content.cloneNode(true) as HTMLElement;
    this.setAttendeeIndex(clone);
    this.fieldsTargets.forEach((input: HTMLInputElement) => {
      clone.querySelector(`.${this.adjustClassName(input.name)}`).textContent = input.value;
    });
    this.addedAttendeesListsAreaTarget.appendChild(clone);
  }

  /**
   * 同席者リストのxボタンに、何人目の同席者であるかの情報を持たせる
   *
   * @param clone
   * @private
   */
  private setAttendeeIndex(clone: HTMLElement) {
    clone.querySelector('button').dataset.formIndex = this.formLength().toString();
  }

  /**
   * 同席者の一覧表示のためクラス名を書き換える
   *
   * @param name
   * @private
   */
  private adjustClassName(name: string) {
    name = name.replace(/\[\d+\]/g, '[]');
    return name.replace(`entry[attendees_fields[][__bookrun_`, '').slice(0, -2);
  }

  /**
   * 同席者の数を返す
   *
   * @private
   */
  private formLength() {
    return this.formsTargets.length;
  }

  /**
   * 指定した同席者を一覧から削除する
   *
   * @param event
   * @private
   */
  private removeList(event: MouseEvent) {
    const currentButton = event.currentTarget as HTMLButtonElement;
    currentButton.closest<HTMLElement>('.attendee_list').remove();
  }

  /**
   * 指定した同席者の入力フォームを削除する
   * どの同席者を指定したかは、data-form-indexで判断している
   *
   * @param event
   * @private
   */
  private removeForm(event: MouseEvent) {
    const currentButton = event.currentTarget as HTMLButtonElement;
    const index = Number(currentButton.dataset.formIndex) - 1;
    this.formsTargets[index].remove();
  }

  /**
   * 何人目の同席者であるかのindexを振り直す
   *
   * @private
   */
  private resetIndex() {
    this.fieldsTargets.forEach((field, index) => {
      const quotient = Math.ceil((index + 1) / this.attendeeFieldNumber());
      field.name = field.name.replace(/\[\d+\]/g, `[${quotient}]`);
    });
    this.listsTargets.forEach((list, index) => {
      list.querySelector('button').dataset.formIndex = (index + 1).toString();
    });
    this.errorsTargets.forEach((error, index) => {
      const quotient = Math.ceil((index + 1) / this.attendeeFieldNumber());
      error.dataset.fieldName = error.dataset.fieldName.replace(/\[\d+\]/g, `[${quotient}]`);
    });
  }

  /**
   * 同席者入力項目の数を返す
   *
   * @private
   */
  private attendeeFieldNumber(): number {
    return this.templateAttendeeListTarget.content.querySelectorAll('.js-attendee-field-item').length;
  }

  /**
   * 追加ボタンのアクティブをトグルする
   * エラーがある場合は無効化、なければ有効化する
   *
   * @private
   */
  private toggleSubmit(): void {
    if (this.hasErrors()) {
      this.disableSubmit();
    } else {
      this.enableSubmit();
    }
  }

  /**
   * error があるかどうか判定する
   *
   * @private
   */
  private hasErrors(): boolean {
    this.fieldsTargets.forEach((field) => {
      const valid = field.checkValidity();
      if (valid) {
        delete this.errors[field.name];
      } else {
        this.errors[field.name] = field.validationMessage;
      }
    });
    return Object.keys(this.errors).length > 0;
  }

  /**
   * 追加ボタンを無効化
   *
   * @private
   */
  private disableSubmit(): void {
    this.submitsTargets.forEach((button) => {
      button.disabled = true;
    });
  }

  /**
   * 追加ボタンを有効化
   *
   * @private
   */
  private enableSubmit(): void {
    this.submitsTargets.forEach((button) => {
      button.disabled = false;
    });
  }

  /**
   * 「他の同席者を追加する」ボタンを無効化
   *
   * @private
   */
  private disabledNewFormButton() {
    this.addAnotherAttendeeTarget.disabled = true;
  }

  /**
   * 「他の同席者を追加する」ボタンを有効化
   *
   * @private
   */
  private enabledNewFormButton() {
    this.addAnotherAttendeeTarget.disabled = false;
  }

  /**
   * 2人目以降の同席者の所属は、入力必須であっても任意に変更する
   * @private
   * @param clone
   */
  private allowNullableCompanyName(clone: HTMLElement) {
    const cloneCompanyName = clone.querySelector('.entry__attendee_company_name');
    if (this.formLength() > 0 && cloneCompanyName) {
      // 必須ラベルを外して任意ラベルをつける
      cloneCompanyName.classList.remove('required');
      cloneCompanyName.classList.add('not_required');
      // inputのrequiredを消してバリデーションにかからないようにする
      clone.querySelector('input[name*="__bookrun_attendee_company_name"]').removeAttribute('required');
    }
  }
}
