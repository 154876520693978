import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field', 'button'];

  declare fieldTarget: HTMLInputElement;
  declare buttonTargets: HTMLButtonElement[];

  select(event: MouseEvent): void {
    this.buttonTargets.forEach((button) => {
      button.dataset.selected = 'false';
    });
    const button = event.currentTarget as HTMLButtonElement;
    const value = button.dataset.value;
    this.fieldTarget.value = value;
    this.fieldTarget.dispatchEvent(new CustomEvent('input', { bubbles: true }));
    button.dataset.selected = 'true';
  }

  /**
   * メインカラーを文字列で指定すると、直前に選択していたカラーパレットの丸を元のサイズで表示する
   */
  setColorWithText() {
    this.buttonTargets.forEach((button) => {
      button.dataset.selected = 'false';
    });
  }
}
