import { Controller } from '@hotwired/stimulus';

/**
 * switch (radio/checkbox) にチェックが入っていると表示、入っていないと非表示にする
 *
 * ### パーツ
 *
 * - switch : 表示を操作するスイッチ radio/checkbox ボタン
 * - content : 表示を操作されるコンテンツ
 */
export default class extends Controller {
  static targets = ['content', 'disableContent', 'switch'];

  declare switchTarget: HTMLInputElement;
  declare contentTarget: HTMLElement;
  declare disableContentTarget: HTMLElement;
  declare hasDisableContentTarget: boolean;

  private contentDisplay: string;
  private disableContentDisplay: string;

  initialize(): void {
    this.contentDisplay = this.contentTarget.style.display;
    if (this.hasDisableContentTarget) {
      this.disableContentDisplay = this.disableContentTarget.style.display;
    }
  }

  connect(): void {
    this.toggle();
  }

  toggle(): void {
    this.contentTarget.style.display = this.switchTarget.checked ? this.contentDisplay : 'none';
    if (this.hasDisableContentTarget) {
      this.disableContentTarget.style.display = this.switchTarget.checked ? 'none' : this.disableContentDisplay;
    }
  }

  toggleAndFocus(): void {
    this.toggle();
    if (this.switchTarget.checked) {
      this.contentTarget.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }
}
