import { Controller } from '@hotwired/stimulus';
import { FrameElement } from 'types';
import ScrollHint from 'scroll-hint';

export default class extends Controller {
  declare frameTarget: FrameElement;
  static targets = ['frame'];

  connect(): void {
    this.frameTarget.loaded.then(() => {
      this.sendDocumentHeight();
      new ScrollHint('.js-scrollable');
    });
  }

  sendDocumentHeight(): void {
    if (window.parent) {
      const params = {
        sender: 'bookrun',
        friendlyKey: `@${this.frameTarget.dataset.friendlyKey}`,
        height: document.body.scrollHeight,
      };
      window.parent.postMessage(params, '*');
    }
  }
}
