import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  update(e: Event): void {
    /**
     * MEMO: 既存 select の値は固定 URL です。
     * eg: /teams/:team_id/book_board_settings/:id/calendar_preview?book_board_setting_frame_minutes=15
     * 他の select を追加すると修正が面倒ですので、クエリパラメータとして追加した方がいい
     **/
    const previewUrl = (e.target as HTMLSelectElement).value;

    window.parent.postMessage({ type: 'previewUpdated', previewUrl }, '*');
    window.Turbo.visit(previewUrl, { frame: 'book_board_calendar' });
  }
}
