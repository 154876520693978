import Choices from 'choices.js';
import { Controller } from '@hotwired/stimulus';
import bookBoardUsersOutletController from 'controllers/book_board_users_controller';
import meetingStyleOutletController from 'controllers/meeting_style_controller';

/**
 *
 * マルチ会議ドロップダウンを作成する
 *
 * ### パーツ
 * - selector : choices.jsの参照要素
 *
 **/
export default class extends Controller {
  static targets = ['selector', 'choice'];
  static outlets = ['book-board-users', 'meeting-style'];
  declare selectorTarget: HTMLSelectElement;
  declare choiceTargets: HTMLElement[];
  declare bookBoardUsersOutlet: bookBoardUsersOutletController;
  declare meetingStyleOutlet: meetingStyleOutletController;
  declare choiceObj: Choices;
  declare hasBookBoardUsersOutlet: boolean;
  declare hasMeetingStyleOutlet: boolean;

  connect(): void {
    this.initChoices();
  }

  initChoices() {
    this.choiceObj = new Choices(this.selectorTarget, {
      searchEnabled: false,
      searchChoices: false,
      removeItemButton: true,
      noResultsText: '登録先の候補がありません',
      noChoicesText: '他に登録先の候補がありません',
      placeholder: true,
      placeholderValue: '場所       ', // 文字数*バイト数で表示widthが決められていそうなので空白を付与している
      // オリジナルテンプレートの指定
      callbackOnCreateTemplates: function (template) {
        return {
          item: ({ classNames }, data) => {
            return template(`
              <div class="${classNames.item}" data-item data-id="${data.id}" data-value="${data.value}">
                ${data.customProperties.icon} ${data.label}
                <button type="button" class="choices__button" aria-label="Remove item: '${data.id}'" data-button="">Remove item</button>
              </div>
            `);
          },
          choice: ({ classNames }, data) => {
            // NOTE: data-idが存在するとmodal-controllerの処理が行えないので、disabledの場合は空文字を設定している
            return template(`
              <div class="${classNames.item} ${classNames.itemChoice} ${
                data.disabled ? classNames.itemDisabled : classNames.itemSelectable
              }" data-choice data-id="${data.disabled ? '' : data.id}" data-value="${data.value}" data-modal="info-disable-meeting-style-${data.value}" data-action="click->modal#open" data-multi-meeting-style-select-target='choice'>
                ${data.customProperties.icon} ${data.label}
              </div>
            `);
          },
        };
      },
    });
    this.searchOffChoicesInput();
  }

  changeMultiStyle(): void {
    const selectedValues = this.choiceObj.getValue(true) as string[];
    // 場所の設定が全て外れるとき、担当者を更新しない
    if (selectedValues.length == 0) {
      return;
    }
    if (this.hasBookBoardUsersOutlet) {
      // outlet では直接プロパティを変更できないので引数で渡す
      this.bookBoardUsersOutlet.resetMultiMeetingStyle(selectedValues);
    }
    if (this.hasMeetingStyleOutlet) {
      this.meetingStyleOutlet.switchContentMultiMeetingStyle(selectedValues as unknown as HTMLOptionsCollection);
    }
  }

  /**
   * 担当者に指定されているユーザーが全員連携している場所以外は選択できないので、都度選択肢の有効状態を更新する
   * 渡されたdisabledItemsに場所名が含まれる選択肢を無効状態にする
   *
   * @param disabledItems
   */
  updateDisabledOption(disabledItems: Array<string>): void {
    this.choiceTargets.forEach((target) => {
      const value = target.dataset.value;
      const isDisabled = disabledItems.includes(value);
      // initChoicesでchoices.jsを利用して生成しているの要素の状態を更新する
      target.classList.toggle('choices__item--disabled', isDisabled);
      target.classList.toggle('choices__item--selectable', !isDisabled);
      const choice = this.choiceObj._currentState.choices.find((choice) => choice.value === value);
      if (choice) {
        choice.disabled = isDisabled;
        target.dataset.id = isDisabled ? '' : `${choice.id}`;
      }
    });
  }

  /**
   * Choices.js で生成された検索用の input を readonly にする
   *
   * @private
   */
  private searchOffChoicesInput(): void {
    const elements = document.querySelectorAll('input[type="search"]');
    elements.forEach((element: HTMLInputElement) => {
      element.readOnly = true;
    });
  }
}
