import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['number'];

  declare numberTarget: HTMLInputElement;

  boundHandleKeydown: (event: KeyboardEvent) => void;

  connect() {
    this.boundHandleKeydown = this.handleKeydown.bind(this);
    this.numberTarget.addEventListener('keydown', this.boundHandleKeydown);
  }

  disconnect() {
    this.numberTarget.removeEventListener('keydown', this.boundHandleKeydown);
  }

  handleKeydown(event: KeyboardEvent) {
    const forbiddenKeys = ['e', '-', '.', '+'];
    if (forbiddenKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
