import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  copy(event: MouseEvent): void {
    const element = event.currentTarget as HTMLElement;
    const text = element.dataset.copyText;
    this.copyToClipboard(text);

    if (element.hasAttribute('data-balloon-visible')) return;
    this.showCopiedLabel(element);
  }

  private copyToClipboard(text: string): void {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  private showCopiedLabel(element: HTMLElement): void {
    const tooltipTarget = element.dataset.tooltipTarget;
    if (tooltipTarget) {
      const target = document.getElementsByClassName(tooltipTarget)[0] as HTMLElement;
      this.setCopiedLavel(target);
      return;
    }
    this.setCopiedLavel(element);
  }

  private setCopiedLavel(element: HTMLElement) {
    const oldAreaLabel = element.getAttribute('aria-label');
    const oldDataPos = element.getAttribute('data-balloon-pos');
    element.setAttribute('aria-label', 'コピーしました');
    element.setAttribute('data-balloon-pos', 'up');
    element.setAttribute('data-balloon-visible', '');
    window.setTimeout(() => {
      element.removeAttribute('data-balloon-visible');
      // 元々設定されていた値があれば戻す
      if (oldAreaLabel && oldDataPos) {
        element.setAttribute('aria-label', oldAreaLabel);
        element.setAttribute('data-balloon-pos', oldDataPos);
      } else {
        element.removeAttribute('aria-label');
        element.removeAttribute('data-balloon-pos');
      }
    }, 1200);
  }
}
