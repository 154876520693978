import { Controller } from '@hotwired/stimulus';
import { navigator } from '@hotwired/turbo';

export default class extends Controller {
  declare formTarget: HTMLFormElement;
  declare stateRadioTargets: HTMLInputElement[];
  declare toggleStateButtonTargets: HTMLInputElement[];

  static targets = ['form', 'stateRadio', 'toggleStateButton'];

  search(): void {
    const formData = new FormData(this.formTarget);
    const queryString = new URLSearchParams(formData as any).toString(); // eslint-disable-line @typescript-eslint/no-explicit-any
    const url = location.origin + location.pathname + '?' + queryString;
    this.formTarget.dispatchEvent(new CustomEvent('submit', { bubbles: true }));
    navigator.history.push(new URL(url));
  }

  toggleState(event: MouseEvent): void {
    const button = event.currentTarget as HTMLButtonElement;
    const state = button.dataset.state;
    this.stateRadioTargets.forEach((element: HTMLInputElement) => {
      element.checked = element.value === state;
    });
    // FIXME: 直す
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.toggleStateButtonTargets.forEach((button: HTMLButtonElement) => {
      button.classList.remove('active');
    });

    button.classList.add('active');
    this.search();
  }

  locateEntryDetail(event: MouseEvent): void {
    const link = event.currentTarget as HTMLAnchorElement;
    navigator.history.push(new URL(link.href));
  }

  /**
   * モバイル用にサイドメニューをドロワーで表示したり、隠したりする
   */
  showFilterMenu(): void {
    this.element.classList.toggle('mobile_menu__active');
  }
}
