import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select'];

  declare selectTarget: HTMLElement;

  changeSelect(event: MouseEvent): void {
    const selected = event.currentTarget as HTMLElement;
    const elements = this.selectTarget.querySelectorAll('div');
    elements.forEach((element) => {
      element.classList.remove('active');
      if (element.dataset.value == selected.dataset.value) {
        element.classList.add('active');
      }
    });
  }
}
