import { Controller } from '@hotwired/stimulus';
import meetingStyleOutletController from 'controllers/meeting_style_controller';

const WEB_METTING_STYLE = ['google_meet', 'teams', 'zoom'];

export default class extends Controller {
  static targets = ['tabContents', 'tabs'];
  static classes = ['active'];
  static values = {
    relActiveTab: String,
  };

  declare tabsTargets: HTMLButtonElement[];
  declare tabContentsTargets: HTMLElement[];
  declare activeClass: string;
  declare relActiveTabValue: string;
  declare hasMeetingStyleOutlet: boolean;

  static outlets = ['meeting-style'];
  declare meetingStyleOutlet: meetingStyleOutletController;

  private contentDisplay: string;

  connect(): void {
    this.initDefaultActiveTab();
  }

  switch(event: Event): void {
    const button = event.currentTarget;
    this.toggleActiveTab(button);
  }

  switchMeetingStyleSetting(event: Event): void {
    const elements = document.getElementsByName((event.currentTarget as HTMLElement).dataset.relName);

    elements.forEach((element) => {
      const el = element as HTMLInputElement;
      if (el.type === 'radio' && el.checked) {
        this.toggleMeetingStyle(el.value);
        if (this.hasMeetingStyleOutlet) {
          this.meetingStyleOutlet.switchContentMeetingStyle(el.value);
        }
      } else if (el.type === 'select-multiple') {
        const options = (element as HTMLSelectElement).options;
        this.toggleMultiMeetingStyle(options);
        if (this.hasMeetingStyleOutlet) {
          this.meetingStyleOutlet.switchContentMultiMeetingStyle(options);
        }
      }
    });
  }

  switchMultiMeetingStyle(event: Event): void {
    const options = (event.currentTarget as HTMLSelectElement).options;
    this.toggleMultiMeetingStyle(options);
  }

  /**
   * 該当タブをクリックした際に、連動して他のタブグループのアクティブタブを切り替える
   *
   * data-rel-active-tab="連動タブグループ名:アクティブにしたいタブ名"
   * data-rel-active-tab-exclude-tabs="連動先のタブグループ除外タグ名（カンマ区切り複数指定可能）"
   *
   * data-rel-active-tab-exclude-tabs は、連動先のタブグループで現時点でアクティブになっているタブが
   * 指定のものであった場合、強制でアクティブ変更はしない除外条件（説明が難しい…）
   *
   * @param event
   */
  changeRelActiveTab(event: Event): void {
    if (!(event.currentTarget instanceof HTMLElement)) {
      return;
    }
    const activatedTab = event.currentTarget;
    const relActiveTab = activatedTab.dataset.relActiveTab;
    const [id, tab] = relActiveTab.split(':');
    const relTabGroup = document.getElementById(id);

    // 連動条件から除外したいタブがない場合は、そのままタブをアクティブにする
    if (!event.currentTarget.dataset.relActiveTabExcludeTabs) {
      relTabGroup.dataset.switchTabRelActiveTabValue = tab;
      return;
    }

    // 連動させたいタブグループで現在アクティブなタブ要素を取得
    const currentRelActiveTab = relTabGroup.querySelector(`.${this.activeClass}[data-switch-tab-target]`);
    if (!currentRelActiveTab || !(currentRelActiveTab instanceof HTMLElement)) {
      return;
    }

    // 強制アクティブ変更の対象外指定があった場合はチェックする
    const excludeTabs = activatedTab.dataset.relActiveTabExcludeTabs.split(',');
    if (!excludeTabs.includes(currentRelActiveTab.dataset.tab)) {
      relTabGroup.dataset.switchTabRelActiveTabValue = tab;
    }
  }

  /**
   * data-active-tab の値が変わった時のコールバック
   * 関連タブを連動してアクティブにするのに利用する
   */
  relActiveTabValueChanged(): void {
    this.tabsTargets.forEach((tab) => {
      if (tab.dataset.tab === this.relActiveTabValue) {
        this.toggleActiveTab(tab);
        this.relActiveTabValue = null;
      }
    });
  }

  /**
   * SwitchTab グループの外から強制的に指定のタブに切り替える
   * 実装的にはシンプルでタブを代理でクリックしているだけ
   *
   * @param event
   */
  forceSwitchTab(event: MouseEvent): void {
    const trigger = event.currentTarget as HTMLElement;
    const tab = trigger.dataset.tab;
    const button = document.getElementById(tab);
    if (button) {
      button.click();
    }
  }

  /**
   * デフォルトでアクティブにするタブを見つけて設定する
   *
   * @private
   */
  private initDefaultActiveTab(): void {
    this.tabContentsTargets.forEach((content) => {
      if (content.dataset.tabDefault === 'true') {
        const tabName = content.dataset.tab;
        const tab = this.tabsTargets.find((tab) => tab.dataset.tab === tabName);
        if (!tab) {
          return;
        }
        tab.classList.add(this.activeClass);
        content.style.display = this.contentDisplay;
      } else {
        this.contentDisplay = content.style.display;
        content.style.display = 'none';
      }
    });
  }

  /**
   * 指定されたタブボタンをアクティブにする
   *
   * @param activateTabButton
   * @private
   */
  private toggleActiveTab(activateTabButton): void {
    const targetTab = activateTabButton.dataset.tab;

    this.tabsTargets.forEach((tab) => {
      tab.classList.remove(this.activeClass);
    });
    activateTabButton.classList.add(this.activeClass);
    this.tabContentsTargets.forEach((content) => {
      if (content.dataset.tab === 'web-meeting') {
        content.style.display = WEB_METTING_STYLE.includes(targetTab) ? this.contentDisplay : 'none';
        return;
      }

      if (content.dataset.tab === targetTab) {
        content.style.display = this.contentDisplay;
      } else {
        content.style.display = 'none';
      }
    });
  }

  /**
   * シングル会議関連タブをアクティブ/非アクティブに切り替える
   *
   * @param tabName
   * @private
   */
  private toggleMeetingStyle(tabName): void {
    this.tabContentsTargets.forEach((content) => {
      if (content.dataset.tab === 'web-meeting') {
        content.style.display = WEB_METTING_STYLE.includes(tabName) ? this.contentDisplay : 'none';
        return;
      }

      if (content.dataset.tab === tabName) {
        content.style.display = this.contentDisplay;
      } else {
        content.style.display = 'none';
      }
    });
  }

  /**
   * マルチ会議関連タブをアクティブ/非アクティブに切り替える
   *
   * @param options
   * @private
   */
  private toggleMultiMeetingStyle(options: HTMLOptionsCollection): void {
    const hasWebMeetingStyle = !!Array.from(options).find((option) => WEB_METTING_STYLE.includes(option.value));

    this.tabContentsTargets.forEach((content) => {
      if (content.dataset.tab === 'web-meeting') {
        content.style.display = hasWebMeetingStyle ? this.contentDisplay : 'none';
        return;
      }

      const option = Array.from(options).find((option) => option.value === content.dataset.tab);

      if (typeof option !== 'undefined' && option.selected) {
        content.style.display = this.contentDisplay;
      } else {
        content.style.display = 'none';
      }
    });
  }
}
