import { Controller } from '@hotwired/stimulus';
import bookBoardUsersOutletController from 'controllers/book_board_users_controller';

export default class extends Controller {
  static outlets = ['book-board-users'];
  declare textTarget: HTMLElement;
  declare bookBoardUsersOutlet: bookBoardUsersOutletController;
  declare hasBookBoardUsersOutlet: boolean;

  changeStyleSetting(event: MouseEvent): void {
    const target = event.currentTarget as HTMLInputElement;
    if (!target.checked) {
      return;
    }

    if (this.hasBookBoardUsersOutlet) {
      // outlet では直接プロパティを変更できないので引数で渡す
      this.bookBoardUsersOutlet.resetMeetingStyleSetting(target.value);
    }
  }
}
