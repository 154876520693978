import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select', 'field'];

  declare selectTarget: HTMLSelectElement;
  declare fieldTarget: HTMLInputElement;

  connect(): void {
    if (this.fieldTarget.value && !this.selectTarget.value) {
      this.selectTarget.value = 'other';
      this.showInput();
    } else {
      this.hideInput();
    }
  }

  selectValue(): void {
    if (this.selectTarget.value === 'other') {
      this.showInput();
      this.fieldTarget.value = '';
    } else {
      this.hideInput();
      this.fieldTarget.value = this.selectTarget.value;
    }
    this.fieldTarget.dispatchEvent(new CustomEvent('input', { bubbles: true }));
  }

  private showInput(): void {
    this.fieldTarget.style.display = 'block';
  }

  private hideInput(): void {
    this.fieldTarget.style.display = 'none';
  }
}
