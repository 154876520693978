import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['beginDate', 'beginCheck', 'endDate', 'endCheck', 'begin', 'end'];

  declare beginDateTarget: HTMLInputElement;
  declare beginCheckTarget: HTMLInputElement;
  declare endDateTarget: HTMLInputElement;
  declare endCheckTarget: HTMLInputElement;
  declare beginTarget: HTMLInputElement;
  declare endTarget: HTMLInputElement;

  connect(): void {
    this.check();
  }

  /**
   * 開始日付 と 終了日付 を比較し、validator_controller.ts の必須チェックと連動させる HTMLInputElementに値を設定する
   */
  check(): void {
    if (!this.beginDateTarget.value || !this.endDateTarget.value) {
      this.beginCheckTarget.value = 'ok';
      this.endCheckTarget.value = 'ok';
      this.beginTarget?.classList.remove('begin_end_error');
      this.endTarget?.classList.remove('begin_end_error');
      return;
    }

    const fromDate = new Date(this.beginDateTarget.value);
    const endDate = new Date(this.endDateTarget.value);

    if (fromDate > endDate) {
      this.beginCheckTarget.value = '';
      this.endCheckTarget.value = '';
      this.beginTarget?.classList.add('begin_end_error');
      this.endTarget?.classList.add('begin_end_error');
    } else {
      this.beginCheckTarget.value = 'ok';
      this.endCheckTarget.value = 'ok';
      this.beginTarget?.classList.remove('begin_end_error');
      this.endTarget?.classList.remove('begin_end_error');
    }
  }
}
