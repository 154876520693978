import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['list', 'checkboxes'];
  private closeLayer = null;

  declare listTarget: HTMLElement;
  declare checkboxesTargets: HTMLInputElement[];

  connect(): void {
    if (this.element instanceof HTMLElement) {
      this.element.dataset.scopePath = 'stimulus/rich_selector';
    }
    this.appendCloseLayer();
    this.hideList();
  }

  showList(): void {
    this.listTarget.style.display = 'block';
    this.showCloseLayer();
  }

  private hideList(): void {
    this.listTarget.style.display = 'none';
    this.hideCloseLayer();
  }

  private showCloseLayer(): void {
    this.closeLayer.style.display = 'block';
  }

  private hideCloseLayer(): void {
    this.closeLayer.style.display = 'none';
  }

  private appendCloseLayer(): void {
    this.closeLayer = document.createElement('div');
    this.closeLayer.classList.add('close__layer');
    this.closeLayer.addEventListener('click', () => {
      this.hideList();
    });
    this.listTarget.appendChild(this.closeLayer);
  }
}
