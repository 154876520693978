import { Controller } from '@hotwired/stimulus';

/**
 * ツールチップを表示する
 *
 * <div data-controller="tooltip"
 *      data-tooltip="ヘルプ"
 *      data-action="mouseover->tooltip#show mouseleave->tooltip#hide"
 * ></div>
 */
export default class extends Controller {
  static targets = ['button'];
  private tooltip: HTMLElement;
  declare buttonTarget: HTMLElement;

  connect(): void {
    if (this.element instanceof HTMLElement) {
      this.element.style.position = 'relative';
      this.tooltip = document.createElement('div');
      this.tooltip.classList.add('tooltip');
      this.tooltip.textContent = this.element.dataset.tooltip;
      this.hide();
      this.element.appendChild(this.tooltip);
    }
  }

  toggle(): void {
    if (!this.buttonTarget.classList.contains('active')) {
      this.hide();
    }
  }

  show(): void {
    this.tooltip.style.display = 'block';
  }

  hide(): void {
    this.tooltip.style.display = 'none';
  }
}
