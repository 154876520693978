import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];
  private origin: string;

  declare inputTarget: HTMLInputElement;

  connect(): void {
    if (!(this.element instanceof HTMLElement)) {
      return;
    }

    this.origin = this.element.dataset.origin;

    window.addEventListener(
      'message',
      (event) => {
        if (event.origin !== this.origin) {
          return;
        }
        if (!event.data.url?.startsWith('https://hooks.slack.com/services/')) {
          return;
        }
        this.inputTarget.value = String(event.data.url);
      },
      false,
    );
  }

  open(): void {
    if (!(this.element instanceof HTMLElement)) {
      return;
    }
    const url = this.element.dataset.oauthUrl;
    this.openSlackOAuthWindow(url);
  }

  private openSlackOAuthWindow(url) {
    const width = 780;
    const height = 740;
    const x = (screen.availWidth - width) / 2;
    const y = (screen.availHeight - height) / 2;
    const windowFeature = `resizable,scrollbars,status,width=${width},height=${height},top=${y},left=${x}`;
    window.open(url, 'slackWindow', windowFeature);
  }
}
