import { Controller } from '@hotwired/stimulus';
import MicroModal from 'micromodal';

// MicroModal を開く
export default class extends Controller {
  connect(): void {
    if (this.element instanceof HTMLElement) {
      const modalId = this.element.dataset.modalId;
      MicroModal.show(modalId);
    }
  }
}
