import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'custom'];

  declare inputTarget: HTMLInputElement;
  declare customTarget: HTMLInputElement;

  connect(): void {
    this.customTarget.addEventListener('input', () => {
      this.checkedNumberValue();
      this.inputTarget.value = this.customTarget.value;
      this.inputTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }));
    });

    const checkedRadio: HTMLInputElement = this.element.querySelector("input[type='radio']:checked");
    if (!checkedRadio) {
      this.customTarget.value = this.inputTarget.value;
      this.checkedNumberValue();
    }
  }

  selectValue(): void {
    const checkedRadio: HTMLInputElement = this.element.querySelector("input[type='radio']:checked");
    if (checkedRadio) {
      this.inputTarget.value = checkedRadio.value;
      this.inputTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }));
      this.customTarget.value = '';
      this.customTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }));
    }
  }

  selectCustomValue(): void {
    const checkedValue = this.customTarget.value;
    this.inputTarget.value = checkedValue;
    this.checkedNumberValue();
    this.inputTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }));
  }

  checkedNumberValue(): void {
    const customRadio: HTMLInputElement = this.element.querySelector("input[type='radio'][value='custom']");
    customRadio.checked = true;
  }
}
