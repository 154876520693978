import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    price: Number,
  };
  declare priceValue: number;

  calc(event) {
    const target = event.currentTarget;
    const total = target.value * this.priceValue;
    document.getElementById('payment_val__team').textContent = '\xA5 ' + total.toLocaleString();
  }
}
