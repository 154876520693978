import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['email', 'template'];

  declare emailTargets: HTMLInputElement[];
  declare templateTarget: HTMLTemplateElement;

  validateEmail() {
    const modal = document.querySelector('#notify__email');
    if (!this.checkBoxIsChecked()) {
      modal.classList.remove('is-open');
      return;
    }
    const emailRegex = /^\w+([-_.]\w+)*@[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;
    const beforeEmailInputs = this.emailTargets;
    // xボタンで取り消したメールアドレスをvalidationの対象から除外する
    const afterEmailInputs = [];
    beforeEmailInputs.forEach((elm) => {
      if (elm.parentElement.style.display != 'none') {
        afterEmailInputs.push(elm);
      }
    });
    const hasInvalidEmail = afterEmailInputs.some((email) => !emailRegex.test(email.value));
    if (hasInvalidEmail) {
      this.flashHtml();
      return;
    } else {
      modal.classList.remove('is-open');
    }
  }

  checkBoxIsChecked() {
    const notifyEmailToOther = document.querySelector('#book_board_setting_notify_email_to_other') as HTMLInputElement;
    return notifyEmailToOther.checked;
  }

  flashHtml(): void {
    const flash = document.querySelector('#flash-alerts') as HTMLElement;
    const content = this.templateTarget.content;
    flash.appendChild(document.importNode(content, true));
  }
}
