import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];

  declare buttonTarget: HTMLButtonElement;

  toggle(): void {
    this.buttonTarget.classList.toggle('active');
  }

  close(): void {
    this.buttonTarget.classList.remove('active');
  }
}
