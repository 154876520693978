import { Controller } from '@hotwired/stimulus';
import MicroModal from 'micromodal';

// MicroModal を開く
export default class extends Controller {
  static targets = ['loading'];
  declare loadingTarget: HTMLElement;

  static values = {
    editPath: String,
  };
  declare editPathValue: string;

  connect(): void {
    MicroModal.show('modal-1', {
      onClose: () => {
        this.closeByOverlay(this.editPathValue);
      },
    });
  }

  /**
   * モーダル外のクリックと escape キーでモーダルを閉じる場合のリダイレクト
   *
   * @param redirectPath
   */
  private closeByOverlay(redirectPath): void {
    // FIXME: 直す
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (event?.target?.className == 'modal__overlay' || event?.key == 'Escape') {
      // escape キー押下時に location.href も escape される問題回避
      event.preventDefault();
      event.stopPropagation();
    }
    // escape キー押下時のダイアログを表示させない
    this.element['leave-alert'].delete_alert();
    // モーダルが消えリダイレクトの瞬間、一瞬「保存」ボタンが押せるのでローディングを表示して押せない様にする
    this.loadingTarget.style.display = 'flex';
    // 編集画面へリダイレクト（再読み込み）
    location.href = redirectPath;
  }
}
