import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  close(): void {
    if (window.opener) {
      window.opener.postMessage('close', '*');
    }
    window.close();
  }
}
