import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tooltip'];
  declare tooltipTarget: HTMLElement;
  // cookie の key
  private keyName = 'invitationsTooltip';
  // 通知間隔（ミリ秒）
  private interval = 3 * 60 * 60 * 1000; // 3時間

  connect(): void {
    // 前回の表示からの経過時間（ミリ秒）
    const elapsed_time = Date.now() - Number(localStorage.getItem(this.keyName));
    // 前回の表示から既定の時間を経過していれば表示する
    if (elapsed_time > this.interval) {
      this.tooltipTarget.style.display = 'inline-block';
    }
  }

  /**
   * ツールチップの非表示
   */
  close(): void {
    // 最後に表示した時刻を保存
    localStorage.setItem(this.keyName, `${Date.now()}`);
    this.tooltipTarget.style.display = 'none';
  }
}
