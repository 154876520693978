import { Controller } from '@hotwired/stimulus';
import MicroModal from 'micromodal';
import { FrameElement } from 'types';

// MicroModal を開く
export default class extends Controller {
  static targets = ['fields'];

  declare fieldsTargets: HTMLInputElement[];

  private restoreData = {};

  initialize(): void {
    MicroModal.init();
    // ブラウザバック → ブラウザフォワードの際にモーダルが表示された状態になっているので閉じています
    document.addEventListener('turbo:before-cache', () => {
      document.querySelector('.modal.is-open')?.classList?.remove('is-open');
    });
  }

  connect(): void {
    this.saveRestoreData();
  }

  cancel(event: MouseEvent): void {
    this.restore();
    this.close(event);
  }

  open(event: MouseEvent): void {
    const button = event.currentTarget as HTMLButtonElement;
    const modalId = button.dataset.modal;
    MicroModal.show(modalId);
  }

  frameOpen(event: MouseEvent): void {
    const button = event.currentTarget as HTMLButtonElement;
    const modalId = button.dataset.modal;
    const turboFrame = document.getElementById(modalId).querySelector('turbo-frame') as FrameElement;
    turboFrame.src = button.dataset.framePath;
    MicroModal.show(modalId);
  }

  close(event: MouseEvent): void {
    const button = event.currentTarget as HTMLButtonElement;
    const modal = button.closest('.modal');
    MicroModal.close(modal.id);
  }

  private saveRestoreData(): void {
    this.fieldsTargets.forEach((field) => {
      if (['radio', 'checkbox'].includes(field.type)) {
        this.restoreData[`${field.name}_${field.value}`] = field.checked;
      } else {
        this.restoreData[field.name] = field.value;
      }
    });
  }

  private restore(): void {
    this.fieldsTargets.forEach((field) => {
      if (['radio', 'checkbox'].includes(field.type)) {
        field.checked = this.restoreData[`${field.name}_${field.value}`];
      } else {
        field.value = this.restoreData[field.name];
      }
    });
  }
}
