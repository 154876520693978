import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'checkboxes'];

  declare buttonTarget: HTMLButtonElement;
  declare checkboxesTargets: HTMLInputElement[];

  connect(): void {
    this.changeLabel();
  }

  exec(): void {
    if (this.isAllSelected()) {
      this.checkboxesTargets.forEach((checkbox) => (checkbox.checked = false));
      this.buttonTarget.textContent = '全選択';
    } else {
      this.checkboxesTargets.forEach((checkbox) => (checkbox.checked = true));
      this.buttonTarget.textContent = '選択解除';
    }
    this.buttonTarget.dispatchEvent(new CustomEvent('allselect', { bubbles: true }));
  }

  changeLabel(): void {
    if (this.isAllSelected()) {
      this.buttonTarget.textContent = '選択解除';
    } else {
      this.buttonTarget.textContent = '全選択';
    }
  }

  private isAllSelected(): boolean {
    return Array.from(this.checkboxesTargets).every((checkbox) => checkbox.checked);
  }
}
