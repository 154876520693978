import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [];

  select(event: MouseEvent) {
    const industryType = 'industry_type';
    const department = 'department';
    const numberOfUsers = 'number_of_users';
    const values = [industryType, department, numberOfUsers];

    event.preventDefault();
    const button = event.currentTarget as HTMLButtonElement;
    document.getElementsByName(button.name).forEach(function (e) {
      e.classList.remove('selected');
    });
    button.classList.add('selected');
    if (button.name == `registration_user_google_form[${industryType}]`) {
      document.getElementById(`hidden-${industryType}`).setAttribute('value', button.value);
      document
        .getElementsByClassName(`li-${industryType}`)[0]
        .getElementsByClassName('normal-icon')[0]
        .setAttribute('style', 'display: none;');
      document
        .getElementsByClassName(`li-${industryType}`)[0]
        .getElementsByClassName('checked-icon')[0]
        .setAttribute('style', 'display: block;');
    } else if (button.name == `registration_user_google_form[${department}]`) {
      document.getElementById(`hidden-${department}`).setAttribute('value', button.value);
      document
        .getElementsByClassName(`li-${department}`)[0]
        .getElementsByClassName('normal-icon')[0]
        .setAttribute('style', 'display: none;');
      document
        .getElementsByClassName(`li-${department}`)[0]
        .getElementsByClassName('checked-icon')[0]
        .setAttribute('style', 'display: block;');
    } else if (button.name == `registration_user[${numberOfUsers}]`) {
      document.getElementById(`hidden-${numberOfUsers}`).setAttribute('value', button.value);
      document.querySelector(`.li-${numberOfUsers} .normal-icon`).setAttribute('style', 'display: none;');
      document.querySelector(`.li-${numberOfUsers} .checked-icon`).setAttribute('style', 'display: block;');
    }

    const isAllCheck = values.every(function (value) {
      return document.getElementById(`hidden-${value}`).getAttribute('value') != null;
    });
    if (isAllCheck) {
      const answerButton = document.getElementsByClassName('answer_button')[0] as HTMLButtonElement;
      answerButton.innerHTML = '日程調整フォームへ';
      answerButton.classList.add('active');
      answerButton.disabled = false;

      const toScheduleAdjustmentButton = document.getElementsByClassName(
        'to__schedule_adjustment__button',
      )[0] as HTMLButtonElement;
      toScheduleAdjustmentButton.classList.add('active');
      toScheduleAdjustmentButton.disabled = false;
    }
  }
}
