import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'button', 'inputHidden'];
  declare inputTarget: HTMLInputElement;
  declare buttonTarget: HTMLButtonElement;
  declare inputHiddenTarget: HTMLInputElement;

  connect() {
    this.buttonTarget.style.display = 'none';
    this.toggle();
  }

  toggle(): void {
    this.buttonTarget.style.display = this.inputTarget.value ? 'block' : 'none';
  }

  clear(): void {
    this.inputHiddenTarget.value = '';
    this.inputTarget.value = '';
    this.buttonTarget.style.display = 'none';
  }
}
