import { Controller } from '@hotwired/stimulus';
import { navigator } from '@hotwired/turbo';

export default class extends Controller {
  static targets = [];

  /**
   * submit だとブラウザによっては Turbo が発火しない場合があるので、requestSubmit でサブミットさせる
   *
   * @param event
   */
  requestSubmit(event: MouseEvent): void {
    const input = event.currentTarget as HTMLInputElement;
    input.form.requestSubmit();
  }

  /**
   * submit だとブラウザによっては Turbo が発火しない場合があるので、requestSubmit でサブミットさせる
   * 公開中の通常のブックボードの公開中、非公開のトグルが動いたタイミングで公開中のブックボードの件数を数える
   *
   * @param event
   */
  requestSubmitOfBookBoard(event: MouseEvent): void {
    const input = event.currentTarget as HTMLInputElement;
    input.form.requestSubmit();
    this.updatePublicBookBoardCount();
  }

  /**
   * 公開中の通常のブックボードとformrun埋め込み用のブックボードと合算した件数を返す
   */
  updatePublicBookBoardCount() {
    const publicCountDisplay = document.getElementById('book_board_public_count');
    const normalPublicBookBoardCount = document.querySelectorAll(
      'input[type="checkbox"][name^="book_board[public]"]:checked',
    ).length;
    const formrunPublicBookBoardCount = parseInt(publicCountDisplay.getAttribute('data-formrun-public-count'));
    publicCountDisplay.textContent = (formrunPublicBookBoardCount + normalPublicBookBoardCount).toString();
  }

  /**
   * フォームサブミットしつつ、URL を書き換える
   *
   * @param event
   */
  submitAndRewriteUrl(event: MouseEvent): void {
    const input = event.currentTarget as HTMLInputElement;
    const formData = new FormData(input.form);
    formData.delete('authenticity_token');
    formData.delete('_method');
    const queryString = new URLSearchParams(formData as any).toString(); // eslint-disable-line @typescript-eslint/no-explicit-any
    const url = location.origin + location.pathname + '?' + queryString;
    input.form.dispatchEvent(new CustomEvent('submit', { bubbles: true }));
    navigator.history.push(new URL(url));
  }

  rewriteQueryParamsAndVisit(event: MouseEvent): void {
    const input = event.currentTarget as HTMLInputElement;
    const queryString = new URLSearchParams(window.location.search);
    queryString.set(input.name, input.value);
    const url = `${location.origin}${location.pathname}?${queryString}`;
    window.location.replace(url);
    navigator.history.push(new URL(url));
  }
}
