import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field', 'counter'];

  declare fieldTarget: HTMLTextAreaElement | HTMLInputElement;
  declare counterTarget: HTMLElement;

  connect() {
    this.count();
  }

  count(): void {
    // MEMO: 絵文字の数を正確に判定するにはライブラリ導入などしないといけない
    // [...str].length でも正確に数えられない絵文字が存在するため…
    // ここでは絵文字含めて正確な文字数判定は必要ないと判断するので素朴に length でいく
    this.counterTarget.textContent = String(this.fieldTarget.value.length);
  }
}
