import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['selector', 'list'];
  private closeLayer = null;

  declare selectorTarget: HTMLInputElement;
  declare listTarget: HTMLElement;

  connect(): void {
    if (this.element instanceof HTMLElement) {
      this.element.dataset.scopePath = 'stimulus/rich_selector';
    }
    this.appendCloseLayer();
    this.hideList();
    this.hideOptions();
  }

  showList(): void {
    this.listTarget.style.display = 'block';
    this.showCloseLayer();
  }

  select(event): void {
    const target = event.currentTarget;
    const value = target.dataset.value;
    this.selectorTarget.value = value;
    this.selectorTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }));
    this.hideList();
  }

  private hideList(): void {
    this.listTarget.style.display = 'none';
    this.hideCloseLayer();
  }

  private showCloseLayer(): void {
    this.closeLayer.style.display = 'block';
  }

  private hideCloseLayer(): void {
    this.closeLayer.style.display = 'none';
  }

  private hideOptions(): void {
    this.selectorTarget.querySelectorAll('option').forEach((option) => {
      option.style.display = 'none';
    });
  }

  private appendCloseLayer(): void {
    this.closeLayer = document.createElement('div');
    this.closeLayer.classList.add('close__layer');
    this.closeLayer.addEventListener('click', () => {
      this.hideList();
    });
    this.listTarget.appendChild(this.closeLayer);
  }
}
