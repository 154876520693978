import { Controller } from '@hotwired/stimulus';

/**
 * フォームページから離脱する際に更新されないアラートを表示する
 */
export default class extends Controller {
  static targets = [];
  static values = {
    setup: Boolean,
  };

  declare setupValue: boolean;

  initialize() {
    this.element[this.identifier] = this; // 他 Controller で呼び出す為の設定
  }

  connect(): void {
    if (this.setupValue) {
      window.addEventListener('beforeunload', this.unloadEvent);
    }
  }

  submit(event: MouseEvent): void {
    window.removeEventListener('beforeunload', this.unloadEvent);
    const button = event.currentTarget as HTMLButtonElement;
    button.form.submit();
  }

  delete_alert(): void {
    window.removeEventListener('beforeunload', this.unloadEvent);
  }

  private unloadEvent(event: MouseEvent): void {
    event.preventDefault();
    // FIXME: 直す
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    event.returnValue = '更新されていない内容があります。戻りますか？';
  }
}
