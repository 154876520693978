import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['state', 'field', 'defaultOn'];

  declare stateTarget: HTMLElement;
  declare fieldTarget: HTMLInputElement;
  declare defaultOnTarget: HTMLInputElement;

  connect(): void {
    this.applyState();
  }

  apply(): void {
    this.applyState();
  }

  defaultSlackOn(): void {
    this.defaultOn();
  }

  private applyState(): void {
    this.stateTarget.textContent = this.fieldTarget.checked ? '通知する' : '通知しない';
  }

  private defaultOn() {
    this.defaultOnTarget.checked = true;
  }
}
