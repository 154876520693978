import Choices from 'choices.js';
import { Controller } from '@hotwired/stimulus';

/**
 *
 * choices.jsを使用する
 *
 * ### パーツ
 * - switch : choicesのactive制御
 * - selector : choices.jsの参照要素
 *
 **/
export default class extends Controller {
  static targets = ['switch', 'selector'];
  declare switchTarget: HTMLInputElement;
  declare selectorTarget: HTMLSelectElement;

  declare choiceObj: Choices;

  connect(): void {
    this.initChoices();
  }

  initChoices() {
    this.choiceObj = new Choices(this.selectorTarget, {
      searchEnabled: false,
      searchChoices: false,
      removeItemButton: true,
      shouldSort: true,
      shouldSortItems: true,
      sorter: function (a, b) {
        return a.value - b.value;
      },
      noResultsText: '登録先の候補がありません',
      noChoicesText: '他に登録先の候補がありません',
      placeholder: true,
      placeholderValue: 'メンバーを選択       ', // 文字数*バイト数で表示widthが決められていそうなので空白を付与している
      itemSelectText: '',
      // オリジナルテンプレートの指定
      callbackOnCreateTemplates: function (template) {
        return {
          item: ({ classNames }, data) => {
            return template(`
              <div class="${classNames.item}" data-item data-id="${data.id}" data-value="${data.value}">
                <img width="24" height="24" class="user__icon" src="${data.customProperties.iconUrl}" /> ${data.label}
                <button type="button" class="choices__button" aria-label="Remove item: '${data.id}'" data-button="">Remove item</button>
              </div>
            `);
          },
          choice: ({ classNames }, data) => {
            return template(`
              <div class="${classNames.item} ${classNames.itemChoice} ${
                data.disabled ? classNames.itemDisabled : classNames.itemSelectable
              }" data-choice data-choice-selectable data-id="${data.id}" data-value="${data.value}">
                <img width="24" height="24" class="user__icon" src="${data.customProperties.iconUrl}" /> ${data.label}
              </div>
            `);
          },
        };
      },
    });
    this.searchOffChoicesInput();
  }

  /**
   * 「担当者」の変更に応じてドロップダウンの項目（その他メンバーの連携カレンダーに予定を自動登録）を変更
   */
  resetSelector(): void {
    const choiceList = [];
    // 選択済みの値を保管
    const currentSelectedValues = this.choiceObj.getValue(true) as string[];
    // Choices.js のリストをクリア
    this.choiceObj.clearStore();

    // 「担当者(同席者)」の <select> 取得
    const usersSelector = document.getElementById('book_board_setting_user_ids') as HTMLSelectElement;
    const usersSelectedOptionList = Array.from(usersSelector.selectedOptions);
    const usersSelectedValues = usersSelectedOptionList.map((option) => option.value);
    // 「担当者(同席者)」の <datalist> 取得
    const userDatalist = document.getElementById('user_datalist') as HTMLDataListElement;
    const userDatalistOptionList = Array.from(userDatalist.options);

    userDatalistOptionList.forEach((option) => {
      // 選択されていないユーザーを抽出
      if (usersSelectedValues.includes(option.dataset.value)) return;
      const optionData = {
        value: option.dataset.value,
        label: option.dataset.label,
        selected: currentSelectedValues.includes(option.dataset.value),
        customProperties: {
          iconUrl: option.dataset.iconUrl,
        },
      };
      choiceList.push(optionData);
    });

    // Choices.js にリストを再設定
    this.choiceObj.setChoices(choiceList, 'value', 'label', false);
  }

  /**
   * Choices.js で生成された検索用の input を readonly にする
   *
   * @private
   */
  private searchOffChoicesInput(): void {
    const elements = document.querySelectorAll('input[type="search"]');
    elements.forEach((element: HTMLInputElement) => {
      element.readOnly = true;
    });
  }
}
