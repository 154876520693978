import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['container'];

  declare containerTarget: HTMLElement;

  connect(): void {
    this.checkDisplay();
  }

  /**
   * hide_trial_expired_alert_untilが設定されていて、その日付が現在よりも未来の場合以外はアラートを表示する
   * NOTE: 日付が変わったタイミングで再表示させるため、時間は考慮せず、年月日のみで比較する
   */
  checkDisplay(): void {
    const hideUntilValue = localStorage.getItem('hide_trial_expired_alert_until');
    const hideUntilDate = new Date(hideUntilValue);
    const hideUntilDateStart = new Date(
      hideUntilDate.getFullYear(),
      hideUntilDate.getMonth(),
      hideUntilDate.getDate(),
    ).getTime();
    const today = new Date();
    const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime();
    // NOTE: 有効なDate型かもチェックしている
    if (hideUntilValue === null || Number.isNaN(hideUntilDateStart) || todayStart > hideUntilDateStart) {
      this.containerTarget.classList.remove('hidden');
      localStorage.removeItem('hide_trial_expired_alert_until');
    }
  }

  /**
   * 要素を非表示にし、非表示にした日の間はアラートを表示しないようにする
   */
  close(): void {
    this.containerTarget.classList.add('hidden');
    const hideAlertUntilDate = new Date();
    localStorage.setItem('hide_trial_expired_alert_until', hideAlertUntilDate.toString());
  }
}
