import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  confirm(event: MouseEvent): void {
    const result = window.confirm('本当に削除してよろしいですか？');
    if (!result) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}
