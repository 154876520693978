import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'button', 'input'];

  declare contentTarget: HTMLElement;
  declare buttonTarget: HTMLButtonElement;
  declare inputTargets: HTMLInputElement[];

  connect() {
    this.contentTarget.style.display = 'none';
  }

  display() {
    this.contentTarget.style.display = 'block';
    this.buttonTarget.style.display = 'none';
    this.enabledInputs();
  }

  cancel() {
    this.contentTarget.style.display = 'none';
    this.buttonTarget.style.display = 'block';
    this.disabledInputs();
  }

  private disabledInputs() {
    this.inputTargets.forEach((input) => {
      input.disabled = true;
    });
  }

  private enabledInputs() {
    this.inputTargets.forEach((input) => {
      input.disabled = false;
    });
  }
}
