import { Controller } from '@hotwired/stimulus';

// <select> で用意している値以外にカスタム入力欄に切り替える
export default class extends Controller {
  static targets = ['select', 'input'];

  declare selectTarget: HTMLSelectElement;
  declare inputTarget: HTMLInputElement;

  connect(): void {
    let selected = false;
    for (let i = 0; i < this.selectTarget.options.length; i++) {
      if (this.selectTarget.options[i].value === this.inputTarget.value) {
        this.selectTarget.options[i].selected = true;
        selected = true;
      }
    }
    if (!selected) {
      this.selectTarget.options[this.selectTarget.options.length - 1].selected = true;
    }
    this.toggleInputParts();
  }

  selectValue(): void {
    this.toggleInputParts();
    this.setValue();
  }

  toggleInputParts(): void {
    if (this.selectTarget.value) {
      this.inputTarget.style.display = 'none';
    } else {
      this.inputTarget.style.display = 'inline-block';
    }
  }

  setValue(): void {
    if (this.selectTarget.value && this.inputTarget.value != this.selectTarget.value) {
      this.inputTarget.value = this.selectTarget.value;
      this.inputTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }));
    }
  }
}
