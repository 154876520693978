import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['text', 'radio', 'field'];

  declare textTarget: HTMLInputElement;
  declare radioTargets: HTMLInputElement[];
  declare fieldTarget: HTMLInputElement;

  private contentDisplay: string;

  connect(): void {
    this.initDefaultActivePlaceholder();
  }

  /**
   * 該当ラジオボタンをクリックした際に、連動して該当フィールドとプレースホルダーを切り替える
   *
   * @param event
   */
  changeRelActiveRadio(event: Event): void {
    if (!(event.currentTarget instanceof HTMLElement)) {
      return;
    }
    const activatedRadio = event.currentTarget;
    const placeholder = activatedRadio.dataset.placeholder;
    const fieldShow = activatedRadio.dataset.fieldShow;
    this.textTarget.placeholder = placeholder;
    this.fieldTarget.innerHTML = fieldShow;
  }

  /**
   * デフォルトでアクティブにするフィールドとプレースホルダーを切り替える
   *
   * @private
   */
  private initDefaultActivePlaceholder(): void {
    this.radioTargets.forEach((radio) => {
      if (radio.checked) {
        const placeholder = radio.dataset.placeholder;
        const fieldShow = radio.dataset.fieldShow;
        this.textTarget.placeholder = placeholder;
        this.fieldTarget.innerHTML = fieldShow;
      }
    });
  }
}
