import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  declare labelTarget: HTMLSpanElement;
  static targets = ['label'];

  show(event: MouseEvent): void {
    const button = event.currentTarget as HTMLButtonElement;
    button.classList.toggle('show_loading_button');
    if (button.classList.contains('show_loading_button')) {
      this.labelTarget.innerHTML = '<p class="text">変更中...</p>';
      setTimeout(() => {
        button.disabled = true;
      }, 0);
    }
  }

  show_for_license(event: MouseEvent): void {
    const button = event.currentTarget as HTMLButtonElement;
    button.classList.toggle('show_loading_button');
    if (button.classList.contains('show_loading_button')) {
      button.childNodes[1].remove();
      const loadingDiv = document.createElement('div');
      loadingDiv.classList.add('loading');
      loadingDiv.innerHTML = '<p class="text">変更中...</p>';
      button.appendChild(loadingDiv);
      setTimeout(() => {
        button.disabled = true;
      }, 0);
    }
  }
}
