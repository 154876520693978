import { Controller } from '@hotwired/stimulus';
import { debounce } from 'debounce';

export default class extends Controller {
  static targets = ['beginTime', 'endTime', 'errors'];

  declare beginTimeTarget: HTMLInputElement;
  declare endTimeTarget: HTMLInputElement;
  declare errorsTarget: HTMLElement;

  initialize(): void {
    this.validate = debounce(this.validate, 250).bind(this);
  }

  validate(): void {
    const beginTime = this.beginTimeTarget;
    const endTime = this.endTimeTarget;
    if (!beginTime.value || !endTime.value) {
      if (beginTime.dataset.blankFlg == 'true' || endTime.dataset.blankFlg == 'true') {
        this.removeError();
      } else {
        this.errorsTarget.style.display = 'inline-block';
      }
      return;
    } else if (!beginTime.value.match(this.pattern()) || !endTime.value.match(this.pattern())) {
      this.errorsTarget.style.display = 'inline-block';
      return;
    } else if (beginTime.value >= endTime.value) {
      this.removeError();
      this.addError();
      this.toggleUpdateButton(true);
    } else {
      this.removeError();
      this.toggleUpdateButton(false);
    }
  }

  private addError() {
    this.beginTimeTarget.classList.add('error__fields');
    this.endTimeTarget.classList.add('error__fields');
    this.displayErrorMessage();
  }

  private removeError() {
    this.beginTimeTarget.classList.remove('error__fields');
    this.endTimeTarget.classList.remove('error__fields');
    this.hideErrorMessage();
  }

  private displayErrorMessage() {
    const errorElement = this.errorsTarget;
    errorElement.textContent = '時刻範囲が正しくありません';
    errorElement.style.display = 'inline-block';
  }

  private hideErrorMessage() {
    const errorElement = this.errorsTarget;
    errorElement.textContent = '';
    errorElement.style.display = 'none';
  }

  private toggleUpdateButton(disabled: boolean) {
    const updateButton = document.querySelector('.header__update > button') as HTMLInputElement;
    updateButton.disabled = disabled;
  }

  private pattern() {
    return /^((0?[0-9]|1[0-9]|2[0-3]):?[0-5][0-9]|24:00)$/;
  }
}
