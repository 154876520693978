import { Controller } from '@hotwired/stimulus';
import MicroModal from 'micromodal';

export default class extends Controller {
  /**
   * ユーザーが自身の権限を上げようとした場合の警告モーダルを表示する
   *
   * @param event
   */
  call(event: MouseEvent): void {
    const input = event.currentTarget as HTMLInputElement;

    if (input.value == 'owner') {
      MicroModal.show('role_change_to_owner_alert', {
        onClose: () => {
          input.form.reset();
        },
      });
    } else if (input.value == 'admin') {
      MicroModal.show('role_change_to_admin_alert', {
        onClose: () => {
          input.form.reset();
        },
      });
    }
  }
}
